import React, { FC, useState } from "react";
import styles from "./storeMobile.module.scss";

import face from "../../../assets/pages/store/storeMobile/face.png";
import faceSelected from "../../../assets/pages/store/storeMobile/faceSelected.png";
import store from "../../../assets/pages/store/storeMobile/store.png";
import trophie from "../../../assets/pages/store/trophie.png";
import trophieSelected from "../../../assets/pages/store/trophie_selected.png";
import storeSelected from "../../../assets/pages/store/storeMobile/storeSelected.png";
import { getUserScore_getUserScore } from "../../../__generated__/gql-types/getUserScore";
import { getClothesAndAccessories_getClothesAndAccessories as PropsClothes } from "../../../__generated__/gql-types/getClothesAndAccessories";

import { ColumnStoreMobile } from "./ColumnStoreMobile";
import { ColumnAvatarMobile } from "./ColumnAvatarMobile";
import { ColumnChampionshipMobile } from "./ColumnChampionshipMobile/ColumnChampionshipMobile";

interface PropsStoreMobile {
  userScore: getUserScore_getUserScore | undefined;
  clothesAndAccessories: PropsClothes[] | null;
  setClothesAndAccessories: (e: PropsClothes[] | null) => void;
  setUserScore: (e: getUserScore_getUserScore | undefined) => void;
}

const StoreMobile: FC<PropsStoreMobile> = ({
  setClothesAndAccessories,
  setUserScore,
  clothesAndAccessories,
  userScore,
}) => {
  const [tab, setTab] = useState("avatar");
  const [openStore, setOpenStore] = useState(false);

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <div className={styles.headerTab}>
          <div
            className={
              tab === "avatar" ? styles.tabButtonSelect : styles.tabButton
            }
            onClick={() => {
              setTab("avatar");
            }}
          >
            <img src={tab === "avatar" ? faceSelected : face} alt="face" />
            <div className={styles.textSelected}>Avatar</div>
          </div>
          <div
            className={
              tab === "store" ? styles.tabButtonSelect : styles.tabButton
            }
            onClick={() => {
              setTab("store");
            }}
          >
            <img src={tab === "store" ? storeSelected : store} alt="face" />
            <div>Tienda</div>
          </div>
          <div
            className={
              tab === "championship" ? styles.tabButtonSelect : styles.tabButton
            }
            onClick={() => {
              setTab("championship");
            }}
          >
            <img
              src={tab === "championship" ? trophieSelected : trophie}
              alt="face"
            />
            <div>Torneo</div>
          </div>
        </div>
      </div>
      {tab === "avatar" && (
        <ColumnAvatarMobile
          clothesAndAccessories={clothesAndAccessories}
          userScore={userScore}
        />
      )}
      {tab === "store" && (
        <ColumnStoreMobile
          openStore={openStore}
          setOpenStore={setOpenStore}
          setClothesAndAccessories={setClothesAndAccessories}
          setUserScore={setUserScore}
          clothesAndAccessories={clothesAndAccessories}
          userScore={userScore}
        />
      )}
      {tab === "championship" && <ColumnChampionshipMobile />}
    </div>
  );
};

export default StoreMobile;
