import { useEffect, useState } from "react";
import styles from "./singleCourse.module.scss";

// Components
import { Loader } from "../../../Commons";
import { Header } from "../../../Layout/Header";

// Api && Types
import { HeaderMobile } from "../../../Mobile/Headers/HeaderMobile";
import useAuth from "../../../../context/useAuth";
import { Tabs } from "./BannerCards/Tabs";
import BannerCardsNew from "./BannerCards/BannerCardsNew";
import flagMoonIcon from "../../../../assets/icons/flagMoonIcon.svg";
import rocket from "../../../../assets/images/students/banner/rocketBannerSingleCourse.svg";
import useIsMobile from "../../../../hooks/useIsMobile";
import { useLocation, useParams } from "react-router-dom";
import UseUserScore from "../../../../api/useGetUserScore";
import { getUserScore_getUserScore } from "../../../../__generated__/gql-types/getUserScore";
import { encontrarValorMayor } from "../utils";
import { getClassroomsByStudentIdV2_getClassroomsByStudentIdV2 as PropsClassRooms } from "./../../../../__generated__/gql-types/getClassroomsByStudentIdV2";
import { getHomeworksByClassroom_getHomeworksByClassroom as PropsHomeworks } from "./../../../../__generated__/gql-types/getHomeworksByClassroom";
import UseGetHomeworksByClassroom from "../../../../api/useGetHomeworksByClassroom";
import UseClassroomsByStudentIdV2 from "../../../../api/useGetClassroomsByStudentIdV2";
import { DrawerHomework } from "../DrawerHomeworkStudent";
import { CTAButtons } from "../../../../__generated__/gql-types/globalTypes";

const SingleCourse = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const homeworkIdParams = queryParams.get("homeworkId");
  const getUserScore = UseUserScore();
  const getHomeworksByClassroom = UseGetHomeworksByClassroom();
  const getClassroomsByStudentIdV2 = UseClassroomsByStudentIdV2();

  const isMobile = useIsMobile();
  const { id } = useParams();
  const { userToken } = useAuth();
  const [classRoom, setClassRoom] = useState<PropsClassRooms | null>(null);
  const [contReviewStudent, setContReviewStudent] = useState(0);
  const [userScore, setUserScore] =
    useState<getUserScore_getUserScore | null>();
  const [completedTasks, setCompletedTasks] = useState<PropsHomeworks[] | null>(
    null
  );
  const [needReviewTasks, setNeedReviewTasks] = useState<
    PropsHomeworks[] | null
  >(null);
  const [itemFiltered, setItemFiltered] = useState<PropsHomeworks[] | null>(
    null
  );
  const [redactionTasks, setRedactionTasks] = useState<PropsHomeworks[] | null>(
    null
  );
  const [visibleLesson, setVisibleLesson] = useState(
    homeworkIdParams ? true : false
  );
  const [idHomework, setIdHomework] = useState(homeworkIdParams ?? "");

  const onCloseLesson = () => {
    setVisibleLesson(false);
  };

  useEffect(() => {
    (async () => {
      try {
        let cont = 0;
        let misionNeed: PropsHomeworks[] = [];
        let misionCompleted: PropsHomeworks[] = [];
        let misionPen: PropsHomeworks[] = [];
        let misionRedactionFinished: PropsHomeworks[] = [];

        const classRoomRes = await getClassroomsByStudentIdV2();
        if (id && classRoomRes) {
          setClassRoom(
            classRoomRes?.find((classRoom) => classRoom.id === id) ?? null
          );
        }

        const indiCourse = await getHomeworksByClassroom({
          classroomId: Number(id),
        });

        indiCourse?.forEach((homework) => {
          const maxResult = encontrarValorMayor(
            homework?.result ?? 0,
            homework?.post_homework_result ?? 0
          );
          if (
            homework?.users?.length === 0 ||
            homework?.users?.find(
              (user) => Number(user.id) === Number(userToken?.id)
            )
          ) {
            if (
              homework?.id &&
              homework?.lessons &&
              homework?.lessons[0]?.lesson_type === "redaction" &&
              !homework?.redaction_review_by_student &&
              homework?.redaction_correction
            ) {
              cont++;
            }
            if (
              homework?.lessons &&
              homework.lessons[0].lesson_type === "redaction" &&
              homework.cta_button !== CTAButtons.TERMINAR &&
              homework.cta_button !== CTAButtons.TERMINAREVIDENCIA &&
              (homework?.finished ||
                homework.cta_button === CTAButtons.NO_ENTREGADA)
            ) {
              misionRedactionFinished.push(homework);
            }

            if (
              homework.cta_button === CTAButtons.TERMINAR ||
              homework.cta_button === CTAButtons.TERMINAREVIDENCIA ||
              (homework.status === "inCourse" &&
                homework.finished !== true &&
                (!homework?.tries ||
                  (homework?.tries &&
                    homework?.tries - Number(homework?.studentTries) > 0)))
            ) {
              misionPen.push(homework);
            }

            if (maxResult && maxResult >= 6 && homework?.finished) {
              misionCompleted.push(homework);
            }
            if (
              (!maxResult || maxResult < 6) &&
              (homework?.status === "finished" || homework?.finished)
            ) {
              misionNeed.push(homework);
            }
          }
        });
        setContReviewStudent(cont);
        setRedactionTasks(misionRedactionFinished);
        setCompletedTasks(misionCompleted);
        setNeedReviewTasks(misionNeed);
        misionPen.sort((a, b) => {
          const dateA = new Date(
            a?.end_date?.split(" ")[0].split("/").reverse().join("-") +
              "T" +
              a?.end_date?.split(" ")[1]
          ).getTime();
          const dateB = new Date(
            b?.end_date?.split(" ")[0].split("/").reverse().join("-") +
              "T" +
              b?.end_date?.split(" ")[1]
          ).getTime();
          return dateA - dateB;
        });
        setItemFiltered(misionPen);
      } catch (error) {
        // setOpenModalError(true);
      }
    })();
  }, [id, userToken, getHomeworksByClassroom, getClassroomsByStudentIdV2]);

  useEffect(() => {
    (async () => {
      try {
        const userScoreRes = await getUserScore();
        setUserScore(userScoreRes);
      } catch (error) {
        // setOpenModalError(true);
      }
    })();
  }, [getUserScore]);

  return (
    <div className={styles.container}>
      {!isMobile ? (
        <Header title={classRoom?.name ?? ""} back="mymissions" />
      ) : (
        <HeaderMobile title={classRoom?.name ?? ""} openCourse={true} />
      )}
      {!classRoom ? (
        <div className={styles.spinner}>
          <Loader />
        </div>
      ) : (
        <>
          <div className={styles.bannerBlue}>
            <img
              alt="space"
              src={rocket}
              className={styles.imageRocket}
              width={"100%"}
            />
            <div className={styles.containerQuads}>
              <div className={styles.quad}>
                <div className={styles.promedio}>Promedio</div>
                <span className={styles.points}>
                  {typeof classRoom.average_score === "number"
                    ? (classRoom.average_score * 10) / 10
                    : "--"}
                  /<span className={styles.smallPoints}>10</span>
                </span>
              </div>

              <div className={styles.secondQuad}>
                <img
                  src={flagMoonIcon}
                  alt="flagMoon"
                  className={styles.imgSecondQuad}
                />
                <div className={styles.containerTextSecondQuad}>
                  <div className={styles.contMisions}>
                    {completedTasks?.length} Misiones
                  </div>
                  <div className={styles.textSecondQuad}>
                    Superadas con éxito{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <BannerCardsNew
            itemFiltered={itemFiltered}
            setIdHomework={setIdHomework}
            setVisibleLesson={setVisibleLesson}
          />
          <Tabs
            setIdHomework={setIdHomework}
            setVisibleLesson={setVisibleLesson}
            contReviewStudent={contReviewStudent}
            redactionTasks={redactionTasks}
            completedTasks={completedTasks}
            needReviewTasks={needReviewTasks}
          />
        </>
      )}
      {visibleLesson && (
        <DrawerHomework
          visibleLesson={visibleLesson}
          onCloseLesson={onCloseLesson}
          homework_id={idHomework}
        />
      )}
    </div>
  );
};

export default SingleCourse;
