import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  getLessonByIdVariables,
  getLessonById,
} from "../__generated__/gql-types/getLessonById";

const UseGetLessonsById = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getLessonByIdVariables) => {
      const result: ApolloQueryResult<getLessonById> = await client.query({
        query: GET_LESSON_BY_ID,
        fetchPolicy: "no-cache",
        variables: input,
      });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getLessonById;
    },
    [client]
  );
};

export default UseGetLessonsById;

const GET_LESSON_BY_ID = gql`
  query getLessonById($lessonId: ID!) {
    getLessonById(lessonId: $lessonId) {
      id
      title
      description
      cover
      week
      drive_link
      learnings
      lesson_type
      sep_book_pages
      result
      sep_name
      course_id
      subject_id
      course {
        sep_book_pages
        title
      }
      tags {
        name
        id
      }
      axes {
        id
        name
      }
      themes {
        id
        name
      }
      grade {
        id
        name
        grade
      }
      lesson_content {
        id
        pdf
        slide
        listening {
          id
          title
          song {
            text
            order
            audio_file
            activity_number
          }
          listening1 {
            text
            order
            audio_file
            activity_number
          }
          listening2 {
            text
            order
            audio_file
            activity_number
          }
          listening3 {
            text
            order
            audio_file
            activity_number
          }
          created_at
          updated_at
          deleted_at
        }
        aiconversations {
          id
          title
          publish
          level_cerf
          voice
          character_for_role_play
          card_image
          cover_image
          avatar_image
          section_1 {
            question
            order
          }
          section_2 {
            question
            order
          }
          created_at
          updated_at
          deleted_at
        }
        literacy {
          id
          title
          cover
          publish
          header_color
          copyright
          content {
            type
            contentRigth
            contentLeft
            image
            contentType
            text
            order
          }
        }
        responses_pdf
        redaction {
          title
          needs_evidence
          rubrics {
            order
            rubric
          }
          question
          id
        }
        quizzes {
          id
          url
          title
          filename
          order
          questions {
            paragraph
            question
            type_id
            answers {
              id
              answer
              correct
              order
              image
              url
            }
            id
            image
            url
            relationQuestion {
              id
              block1 {
                url
                image
                text
                coincidence
              }
              question_id
              created_at
              updated_at
              deleted_at
              block2 {
                url
                image
                text
                coincidence
              }
            }
            dAndDQuestion {
              id
              blockItems {
                url
                image
                text
                coincidence
              }
              blockContainer {
                url
                image
                text
                coincidence
                totalItems
              }
              question_id
              created_at
              updated_at
              deleted_at
            }
          }
        }
        videos {
          id
          url
          title
          file_id
          metadata
          imageUrl
          microcontent
          key_learnings
          order
          quizzes {
            quiz {
              id
              url
              title
              filename
              order
              questions {
                type_id
                answers {
                  id
                  answer
                  correct
                  order
                  image
                  url
                }
                question
                id
                image
                url
              }
            }
            time
            time_out
          }
        }
      }
      subject {
        id
        code
        name
      }
    }
  }
`;
