import { getLessonById_getLessonById_lesson_content_literacy } from "../../../../../__generated__/gql-types/getLessonById";
import styles from "./literacyContent.module.scss";
import useIsHorizontalMobile from "../../../../../hooks/useIsHorizontalMobile";

interface LiteracyContentProps {
  literacy: getLessonById_getLessonById_lesson_content_literacy | null;
  grade: number;
}

const LiteracyContent = ({ literacy, grade }: LiteracyContentProps) => {
  const isHorizontalMobile = useIsHorizontalMobile();

  const gradeSizes: { [key: number]: { mobile: string; desktop: string } } = {
    1: { mobile: "20px", desktop: "26px" },
    2: { mobile: "20px", desktop: "24px" },
    3: { mobile: "18px", desktop: "22px" },
    4: { mobile: "18px", desktop: "20px" },
    5: { mobile: "16px", desktop: "18px" },
    6: { mobile: "16px", desktop: "18px" },
  };

  const stylesToGrade = (grade: number): string => {
    const sizes = gradeSizes[grade] || { mobile: "20px", desktop: "22px" };
    return isHorizontalMobile ? sizes.mobile : sizes.desktop;
  };

  const replaceSuperscript = (text: string) => {
    // Expresión regular para detectar el patrón: &n&m (donde n y m son números)
    return text.replace(/&(\d+)&(\d+)/g, (match, base, exponent) => {
      return `${base}<sup>${exponent}</sup>`;
    });
  };

  const replaceSubscript = (text: string) => {
    // Expresión regular para detectar el patrón: #n#m (donde n y m son números)
    return text.replace(/#(\d+)#(\d+)/g, (match, base, exponent) => {
      return `${base}<sub>${exponent}</sub>`;
    });
  };

  // Función que aplica ambas transformaciones (superíndice y subíndice)
  const replaceText = (text: string) => {
    // Primero aplicamos la transformación de superíndice y luego la de subíndice
    const superscriptedText = replaceSuperscript(text);
    return replaceSubscript(superscriptedText); // Finalmente, aplicamos la transformación de subíndice
  };

  return (
    <div className={styles.container}>
      {literacy?.content?.map((content) => {
        return (
          <>
            {content?.contentType === null && content?.type === "image" ? (
              content?.image && (
                <div className={styles.containerOneOld}>
                  <img src={content.image} alt="img" />
                </div>
              )
            ) : content?.contentType === null && content?.type === "text" ? (
              <div className={styles.containerCero}>
                {content?.text && (
                  <div>
                    {content?.text.split("\n").map((line) => {
                      return (
                        <div>
                          <p
                            className={styles.fontText}
                            style={{ fontSize: stylesToGrade(grade) }}
                            dangerouslySetInnerHTML={{
                              __html: replaceText(line),
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            ) : null}

            {(content?.contentType === 2 || content?.contentType === 3) && (
              <div className={styles.containerTwo}>
                <img src={content.image ?? ""} alt="altr"></img>
              </div>
            )}

            {content?.contentType === 0 && (
              <div className={styles.containerCero}>
                <div
                  className={styles.fontText}
                  style={{ fontSize: stylesToGrade(grade) }}
                >
                  {" "}
                  {content?.text && (
                    <div>
                      {content?.text.split("\n").map((line) => {
                        return (
                          <div>
                            <p
                              className={styles.fontText}
                              style={{
                                fontSize: stylesToGrade(grade),
                              }}
                              dangerouslySetInnerHTML={{
                                __html: replaceText(line),
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            )}

            {content?.contentType === 1 && (
              <div className={styles.containerOne}>
                {content.contentLeft === "text" ? (
                  <>
                    <div className={styles.textOne}>
                      <div
                        className={styles.fontText}
                        style={{ fontSize: stylesToGrade(grade) }}
                      >
                        {content?.text && (
                          // separacion del texto que esta a la izquierda
                          <div style={{ marginRight: 26 }}>
                            {content?.text.split("\n").map((line) => {
                              return (
                                <div>
                                  <p
                                    className={styles.fontText}
                                    style={{
                                      fontSize: stylesToGrade(grade),
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: replaceText(line),
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                    <img src={content.image ?? ""} alt="altr"></img>
                  </>
                ) : (
                  <>
                    <img src={content.image ?? ""} alt="altr"></img>
                    <div className={styles.textOne}>
                      <div
                        className={styles.fontText}
                        style={{ fontSize: stylesToGrade(grade) }}
                      >
                        {content?.text && (
                          <div>
                            {content?.text.split("\n").map((line) => {
                              return (
                                // separacion del texto que esta a la derecha
                                <div style={{ marginLeft: 26 }}>
                                  <p
                                    className={styles.fontText}
                                    style={{
                                      fontSize: stylesToGrade(grade),
                                    }}
                                    dangerouslySetInnerHTML={{
                                      __html: replaceText(line),
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </>
        );
      })}
    </div>
  );
};

export default LiteracyContent;
