import { PropsBanner } from "../utils";
import styles from "./tableClasification.module.scss";
import arrowUp from "../../../../assets/icons/clasification/arrowUp.svg";
import arrowDown from "../../../../assets/icons/clasification/arrowDown.svg";
import equal from "../../../../assets/icons/clasification/equal.svg";
import { resolveAvatar, resolveColorAvatar } from "../../../../utils/functions";
import { functionReturnImgAvatar } from "../../../Store/ColumnAvatar/ContainerAvatar/utils";

type StateType = "equal" | "up" | "down";
const TableClasification = ({
  tablaScore,
  setOpenModal,
  setUserModal,
}: PropsBanner) => {
  const icons = {
    equal: equal,
    up: arrowUp,
    down: arrowDown,
  };

  return (
    <div className={styles.cardsContainer}>
      {tablaScore?.scores?.map((student: any) => {
        if (![1, 2, 3].includes(student?.position)) {
          let backgroundColor = resolveColorAvatar(
            student.student_avatar ?? "polux"
          );
          return (
            <div
              className={styles.cardContainer}
              key={student.position}
              onClick={() => {
                setUserModal(student);
                setOpenModal(true);
              }}
            >
              <div className={styles.numberContainer}>
                {icons[student.state as StateType] && (
                  <img src={icons[student.state as StateType]} alt="" />
                )}

                <div> {student.position}</div>
              </div>
              <div className={styles.textContainer}>
                <div className={styles.avatarContainer}>
                  <div
                    className={styles.studentAvatar}
                    style={{ background: `${backgroundColor}` }}
                  >
                    {student?.shopActions?.map((clothes: any) => (
                      <img
                        src={clothes.item_img ?? ""}
                        alt="avatar"
                        style={{
                          zIndex: clothes.z_index ? clothes.z_index * 2 : 0,
                        }}
                        className={styles.clothes}
                      />
                    ))}
                    <img
                      src={
                        student?.student_level && student?.student_level < 6
                          ? resolveAvatar(
                              student?.student_avatar ?? "polux",
                              student?.student_level.toString() ?? "1"
                            )
                          : functionReturnImgAvatar(
                              student?.student_avatar ?? ""
                            )
                      }
                      alt="avatar"
                      className={
                        student?.student_level && student?.student_level < 6
                          ? styles.avatarImgwithOutLevel // revisar
                          : styles.avatarImg
                      }
                    />
                  </div>
                  <div className={styles.textName}>{student.name}</div>
                </div>
                <div className={styles.textPoint}>
                  {student.score ?? 0}{" "}
                  <span className={styles.textPointHidden}> puntos</span>
                </div>
              </div>
            </div>
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default TableClasification;
