import React, { FC } from "react";
import styles from "./columnAvatar.module.scss";
import { getUserScore_getUserScore } from "../../../__generated__/gql-types/getUserScore";
import { Progress } from "antd";
import { calculateLevel } from "../../../utils/functions";

import start from "../../../assets/pages/store/start.png";
import gold from "../../../assets/pages/store/copa2.png";
import silver from "../../../assets/pages/store/laPlataa.png";
import bronze from "../../../assets/pages/store/bronze.png";
import questions from "../../../assets/pages/store/questions.png";
import { getClothesAndAccessories_getClothesAndAccessories as PropsClothes } from "../../../__generated__/gql-types/getClothesAndAccessories";

import { ContainerAvatar } from "./ContainerAvatar";
import { formatNumberWithSpaces } from "../utils";
interface PropsColumnAvatar {
  userScore: getUserScore_getUserScore | undefined;
  clothesAndAccessories: PropsClothes[] | null;
}

const ColumnAvatar: FC<PropsColumnAvatar> = ({
  userScore,
  clothesAndAccessories,
}) => {
  const clothesActive = clothesAndAccessories?.filter((e) => e.active);

  const contCup = (positionCup: number) => {
    return userScore?.user?.avatar_state?.trophies?.filter(
      (e) => e.position === positionCup
    ).length;
  };
  return (
    <div className={styles.columnAvatar}>
      <ContainerAvatar
        clothesActive={clothesActive}
        userScore={userScore}
      ></ContainerAvatar>
      <div className={styles.containerLevel}>
        <div className={styles.secondRowPj}>
          <div className={styles.circleProgress}>{userScore?.level}</div>
          <div className={styles.progress}>
            <div className={styles.textLevel}>NIVEL {userScore?.level}</div>
            <Progress
              percent={
                userScore?.main_score
                  ? calculateLevel(userScore?.main_score)
                  : 0
              }
              showInfo={false}
              className={"myProgressStore"}
              strokeColor={"#ffba31"}
            />
          </div>
          <div className={styles.circleProgress}>
            {userScore?.level && userScore?.level + 1}
          </div>
        </div>

        <div className={styles.containerPoints}>
          <div className={styles.containerExp}>
            <div>EXPERIENCIA</div>
            <div className={styles.containerStart}>
              <img src={start} alt="start" />
              <div>{formatNumberWithSpaces(userScore?.main_score)}</div>
            </div>
          </div>

          <div className={styles.containerChampionship}>
            <div className={styles.containerQuestions}>
              <div> TROFEOS</div>
              <div className={styles.containerImg}>
                <div className={styles.containerTooltip}>
                  Estos son los trofeos que has ganado en los torneos quicenales
                  en los que has participado. ¡Sigue realizando misiones, para
                  quedar en las tres primeras posiciones, y ganar más trofeos!
                </div>
                <img src={questions} alt="question" />
              </div>
            </div>
            <div className={styles.containerCopas}>
              <div className={styles.individualCup}>
                <div className={styles.numberCup}>{contCup(1)}</div>

                <img src={gold} alt="bg" />
              </div>
              <div className={styles.individualCup}>
                <div className={styles.numberCup}>{contCup(2)}</div>
                <img src={silver} alt="bg" />
              </div>
              <div className={styles.individualCup}>
                <div className={styles.numberCup}>{contCup(3)}</div>
                <img src={bronze} alt="bg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColumnAvatar;
