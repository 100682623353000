import { FC, useEffect, useState } from "react";
import styles from "./banner.module.scss";
import moon from "../../../../assets/images/students/banner/moonBanner.svg";
import rocket from "../../../../assets/images/students/banner/rocketBanner.svg";
import start from "../../../../assets/icons/starBanner.png";
import plataform from "../../../../assets/images/students/banner/plataformBanner.svg";
import { CardBanner } from "./CardBanner";
import { Progress } from "antd";
import { getUserScore_getUserScore } from "../../../../__generated__/gql-types/getUserScore";
import UseUserScore from "../../../../api/useGetUserScore";
import { calculateLevel, resolveAvatar } from "../../../../utils/functions";
import { Loader } from "../../../Commons";
import useIsMobile from "../../../../hooks/useIsMobile";
import { functionReturnImgAvatar } from "../../../Store/ColumnAvatar/ContainerAvatar/utils";
import UseGetClothesAndAccessories from "../../../../api/useGetClothesAndAccessories";
import { getClothesAndAccessories_getClothesAndAccessories } from "../../../../__generated__/gql-types/getClothesAndAccessories";
import useAuth from "../../../../context/useAuth";
import { getClassroomsByStudentIdV2_getClassroomsByStudentIdV2 } from "../../../../__generated__/gql-types/getClassroomsByStudentIdV2";
import { getHomeworksByClassroom_getHomeworksByClassroom } from "../../../../__generated__/gql-types/getHomeworksByClassroom";

interface BannerProps {
  classRooms: getClassroomsByStudentIdV2_getClassroomsByStudentIdV2[];
  homeworks: (getHomeworksByClassroom_getHomeworksByClassroom | null)[];
}

const Banner: FC<BannerProps> = ({ classRooms, homeworks }) => {
  const getUserScore = UseUserScore();
  const getClothesAndAccessories = UseGetClothesAndAccessories();
  const isMobile = useIsMobile();
  const { userToken } = useAuth();
  const [clothesAndAccessories, setClothesAndAccessories] = useState<
    getClothesAndAccessories_getClothesAndAccessories[] | null
  >(null);
  const [userScore, setUserScore] = useState<getUserScore_getUserScore>();

  useEffect(() => {
    (async () => {
      const userScoreRes = await getUserScore();
      userScoreRes && setUserScore(userScoreRes);
    })();
  }, [getUserScore]);

  useEffect(() => {
    (async () => {
      const response = await getClothesAndAccessories();
      const clothesActive = response?.filter((e) => e.active);
      clothesActive && setClothesAndAccessories(clothesActive);
    })();
  }, [getClothesAndAccessories]);

  const formatNumberWithSpaces = (number: number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };
  
  return (
    <div
      className={styles.container}
      style={{ borderRadius: !isMobile ? "20px" : "0px" }}
    >
      <div className={styles.containerInfo}>
        <div className={styles.bodyContent}>¡Hola {userToken?.first_name}!</div>
        <div className={styles.containerPj}>
          <div className={styles.firstRowPj}>
            <div className={styles.character}>
              {userScore ? (
                <>
                  {clothesAndAccessories?.map((clothes) => (
                    <img
                      src={clothes.item_img ?? ""}
                      alt="avatar"
                      style={{
                        zIndex: clothes.z_index ? clothes.z_index * 2 : 0,
                      }}
                      className={styles.clothes}
                    />
                  ))}
                  <img
                    src={
                      userScore?.level && userScore?.level < 6
                        ? resolveAvatar(
                            userScore?.user?.avatar_state?.name ?? "polux",
                            userScore?.level.toString() ?? "1"
                          )
                        : functionReturnImgAvatar(
                            userScore?.user?.avatar_state?.name ?? ""
                          )
                    }
                    alt="avatar"
                    className={
                      userScore?.level && userScore?.level < 6
                        ? styles.rotateImage // revisar
                        : styles.avatarImg
                    }
                  />
                </>
              ) : (
                <Loader />
              )}
              <img src={plataform} alt="plataform" />
            </div>
            <div className={styles.quad}>
              <div className={styles.textCristales}>EXPERIENCIA</div>
              <div className={styles.gemFont}>
                <img src={start} alt="Gema"></img>
                <div>{formatNumberWithSpaces(userScore?.main_score ?? 0)}</div>
              </div>
            </div>
          </div>

          <div className={styles.secondRowPj}>
            <div className={styles.circleProgress}>{userScore?.level}</div>
            <div className={styles.progress}>
              <div className={styles.textLevel}> NIVEL {userScore?.level}</div>
              <Progress
                percent={
                  userScore?.main_score
                    ? calculateLevel(userScore?.main_score)
                    : 0
                }
                showInfo={false}
                className={"myProgress"}
                strokeColor={"#ffba31"}
              />
            </div>
            <div className={styles.circleProgress}>
              {userScore?.level && userScore?.level + 1}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.containerCards}>
        {classRooms.map((classRoom, index) => {
          return (
            <CardBanner
              key={index}
              homeworks={homeworks}
              classRoom={classRoom}
            />
          );
        })}
      </div>

      <img
        alt="space"
        src={rocket}
        className={styles.imageRocket}
        width={"90%"}
      />
      <img alt="space" src={moon} className={styles.imageMoon} />
    </div>
  );
};

export default Banner;
