import styles from "./bannerClasification.module.scss";
import moon from "../../../../assets/images/students/banner/moonBanner.svg";
import medalFirst from "../../../../assets/icons/clasification/medalFirst.svg";
import medalThired from "../../../../assets/icons/clasification/medalThired.svg";
import medalSecond from "../../../../assets/icons/clasification/medalSecond.svg";
import rocket from "../../../../assets/images/students/banner/rocketBanner.svg";
import { PropsBanner } from "../utils";
import { useEffect, useState } from "react";
import { getMonthName, resolveAvatar } from "../../../../utils/functions";
import { functionReturnImgAvatar } from "../../../Store/ColumnAvatar/ContainerAvatar/utils";

const BannerClasification = ({
  setOpenModal,
  setUserModal,
  tablaScore,
  dateLeader,
  daysLeft,
}: PropsBanner) => {
  const [dia, setDia] = useState(0);
  const [diaMore, setDiaMore] = useState(0);
  const [finishMonth, setFinishMonth] = useState<Date>();
  const firstAlumn = tablaScore?.scores?.find((e) => e.position === 1);
  const secondAlumn = tablaScore?.scores?.find((e) => e.position === 2);
  const thirdAlumn = tablaScore?.scores?.find((e) => e.position === 3);

  const ALUMNS_OPTIONS: Record<string, { alumn: any; img: string }> = {
    firstAlumn: { alumn: firstAlumn, img: medalFirst },
    secondAlumn: { alumn: secondAlumn, img: medalSecond },
    thirdAlumn: { alumn: thirdAlumn, img: medalThired },
  };

  useEffect(() => {
    if (dateLeader) {
      setDia(new Date(Number(dateLeader)).getDate());
      const dateLeaderBecome = new Date(Number(dateLeader));
      dateLeaderBecome.setDate(dateLeaderBecome.getDate() + 14);
      setDiaMore(dateLeaderBecome.getDate());
      setFinishMonth(dateLeaderBecome);
    }
  }, [dateLeader]);

  return (
    <div className={styles.container}>
      {tablaScore && (
        <>
          <div className={styles.containerDate}>
            {daysLeft ? (
              <div className={styles.badgeDate}>
                Concurso quincenal | {dia} al {diaMore} de{" "}
                {getMonthName(
                  new Date(Number(finishMonth ?? "")).getMonth()
                ).toLowerCase()}{" "}
                | quedan {daysLeft} días
              </div>
            ) : (
              <div className={styles.badgeDate}>
                Concurso quincenal finalizado | {dia} al {diaMore} de{" "}
                {getMonthName(
                  new Date(Number(finishMonth ?? "")).getMonth()
                ).toLowerCase()}
              </div>
            )}
          </div>
          <div className={styles.containerCard}>
            {Object.entries(ALUMNS_OPTIONS).map(([, { alumn, img }]) => (
              <div
                className={styles.cardAvatar}
                onClick={() => {
                  alumn && setUserModal(alumn);
                  setOpenModal(true);
                }}
              >
                <div className={styles.medalContainer}>
                  <img src={img} alt="medal" />
                </div>
                <div className={styles.avatarContainer}>
                  {alumn?.shopActions?.map((clothes: any) => (
                    <img
                      src={clothes.item_img ?? ""}
                      alt="avatar"
                      style={{
                        zIndex: clothes.z_index ? clothes.z_index * 2 : 0,
                      }}
                      className={styles.clothes}
                    />
                  ))}
                  <img
                    src={
                      alumn?.student_level && alumn?.student_level < 6
                        ? resolveAvatar(
                            alumn?.student_avatar ?? "polux",
                            alumn?.student_level.toString() ?? "1"
                          )
                        : functionReturnImgAvatar(alumn?.student_avatar ?? "")
                    }
                    alt="avatar"
                    className={
                      alumn?.student_level < 6
                        ? styles.avatarImgwithOutLevel // revisar
                        : styles.avatarImg
                    }
                  />
                </div>
                <div className={styles.textContainer}>
                  <div className={styles.textCard}>{alumn?.name}</div>
                  <div className={styles.textPoints}>{alumn?.score} puntos</div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}

      <img
        alt="space"
        src={rocket}
        className={styles.imageRocket}
        width={"90%"}
      />
      <img alt="space" src={moon} className={styles.imageMoon} />
    </div>
  );
};

export default BannerClasification;
