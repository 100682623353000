import React, { FC } from "react";
import styles from "./drawerNavigation.module.scss";
import cross from "../../../../../assets/images/crossNavigation.png";
import { useNavigate } from "react-router-dom";
import { getUserByEmail_getUserByEmail } from "../../../../../__generated__/gql-types/getUserByEmail";
import { getButtonConfig } from "./utils";
interface DrawerProps {
  onClose: () => void;
  userDb: getUserByEmail_getUserByEmail;
}
const DrawerNavigation: FC<DrawerProps> = ({ onClose, userDb }) => {
  const navigate = useNavigate();
  const buttonConfig = getButtonConfig(userDb);
  const isTeacher = userDb.role === "teacher" || userDb.role === "coordinator";

  const navigateButton = (route: string) => {
    navigate(route);
    onClose();
  };
  return (
    <div className={styles.containerMask} onClick={onClose}>
      <div
        className={styles.containerBody}
        onClick={(e) => e.isPropagationStopped()}
      >
        <div className={styles.containerCross}>
          <img src={cross} alt="asd" onClick={onClose} />
        </div>
        <div className={styles.containerButtons}>
          {Object.entries(buttonConfig)
            .filter(([_, config]) => config.visible) // Filtrar los botones visibles
            .map(([key, { label, img, route }]) => (
              <div
                key={key}
                className={
                  isTeacher ? styles.buttonProgram : styles.buttonProgramStudent
                }
                onClick={() => navigateButton(route)}
              >
                <img src={img} alt={key} />
                <div className={styles.buttonText}>{label}</div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default DrawerNavigation;
