import React, { FC } from "react";
import styles from "./rewardTeacher.module.scss";
import baseAvatar from "../../../../assets/pages/store/teacherMonster.png";
interface RewardProps {
  onCloseModalPosition: () => void;
}
const RewardTeacher: FC<RewardProps> = ({ onCloseModalPosition }) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>El torneo anterior ha finalizado</div>
      <img src={baseAvatar} alt="" />
      <div className={styles.description}>
        Las posiciones y puntos de sus alumnos se han reseteado para este nuevo
        torneo quincenal.
        <br />
        <br />
        Si quieres ver los puntos totales obtenidos por alumno en la plataforma,
        haz clic en su nombre en el torneo.
      </div>
      <div className={styles.button} onClick={onCloseModalPosition}>
        Ver nuevo torneo
      </div>
    </div>
  );
};

export default RewardTeacher;
