import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { updateUserShowNotificationVariables } from "../__generated__/gql-types/updateUserShowNotification";

const useUpdateShowNotification = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: updateUserShowNotificationVariables) => {
      return await client.mutate({
        mutation: UPDATE_SHOW_NOTIFCATION,
        fetchPolicy: "no-cache",
        variables: input,
      });
    },
    [client]
  );
};
export default useUpdateShowNotification;

const UPDATE_SHOW_NOTIFCATION = gql`
  mutation updateUserShowNotification($value: Boolean) {
    updateUserShowNotification(value: $value)
  }
`;
