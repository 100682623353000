/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState, useEffect, useRef } from "react";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Drawer } from "antd";
import { MainLesson } from "../../Teachers/Lessons/MainLesson";
import styles from "./drawer.module.scss";
import deleteIcon from "../../../assets/icons/delete_trash.svg";
import { getLessonById_getLessonById } from "../../../__generated__/gql-types/getLessonById";
import { environment } from "../../../configuration/environment";
import { ActivityIndicator } from "../../ActivityIndicator/ActivityIndicator";
import {
  NotificationPositive,
  notificationNegative,
} from "../../Shared/Notifacation/NotificationLuca";
import UseDeleteClassRoomLesson from "../../../api/useDeleteClassRoomLesson";
import { AssingClassroomLesson } from "../../ClassroomLesson/AssingClassroomLesson";
import { ModalDelete } from "../../Shared/ModalDelete";
import useTeacherDownloadBookPages from "../../../api/useTeacherDownloadBookPages";
import useTeacherDownloadSlidesModActionEvent from "../../../api/useTeacherDownloadSlidesModActionEvent";
import useTeacherClickSeeAsStudentVideoLesson from "../../../api/useTeacherClickSeeAsStudentVideoLesson";
import useTeacherDownloadOD from "../../../api/useTeacherDownloadOD";
import useTeacherClickSeeAsStudentVideoLessonNotNEM from "../../../api/useTeacherClickSeeAsStudentVideoLessonNotNEM";
import useIsMobile from "../../../hooks/useIsMobile";
import useTeacherEditSlides from "../../../api/useTeacherEditSlides";
import { hasDatePassed } from "../../../utils/functions";
import UseGetLessonsById from "../../../api/useGetLessonsById";
import AssingHomeworkDrawer from "../../Homework/Drawer/AssingHomeworkDrawer";
import { ModalFreemiun } from "../../Shared/ModalFreemiun";
import useAuth from "../../../context/useAuth";
import useStudentActionLibrary from "../../../api/useStudentActionsLibrary";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { DependencyRefresh } from "../../../redux/store/store";
import { setDependencyRefresh } from "../../../redux/slices/dependencyRefresh";
import { TitleLessonNew } from "./TitleLessonNew";
import ReactPlayer from "react-player";
import UseClassroomsByTeacher from "../../../api/useGetClassroomsByTeacher";
import TeacherClassroooms from "../../../utils/UseTeacherClassRooms";
import { getHomeworkById_getHomeworkById_lessons } from "../../../__generated__/gql-types/getHomeworkById";

interface DrawerProps {
  visible: boolean;
  onClose: () => void;
  back: string;
  individualLesson:
    | getLessonById_getLessonById
    | getHomeworkById_getHomeworkById_lessons
    | null;
  lessonViewSelected?: string;
  taskId?: string;
  lessonId?: string;
  videoLessonEndDate?: string;
  videoLessonStatusDate?: string;
}

const DrawerLessonNew: FC<DrawerProps> = ({
  onClose,
  visible,
  back,
  taskId,
  lessonId,
  lessonViewSelected,
  individualLesson: individualLessonID,
  videoLessonEndDate,
  videoLessonStatusDate,
}) => {
  const location = useLocation();
  let { lessonIdParams } = useParams();
  const { userToken } = useAuth();
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const dependencyRefresh = useAppSelector(DependencyRefresh);
  const isNem = location.pathname.includes("nem");
  const getLessonsById = UseGetLessonsById();
  const deleteClassRoomLesson = UseDeleteClassRoomLesson();
  const downloadBookPages = useTeacherDownloadBookPages();
  const getClassroomsByTeacher = UseClassroomsByTeacher();
  const downloadSlides = useTeacherDownloadSlidesModActionEvent();
  const ClickSeeAsStudent = useTeacherClickSeeAsStudentVideoLesson();
  const ClickSeeAsStudentNotNEM =
    useTeacherClickSeeAsStudentVideoLessonNotNEM();
  const studentActionsLibrary = useStudentActionLibrary();
  const downloadOD = useTeacherDownloadOD();
  const editSlidesRegisterEvent = useTeacherEditSlides();
  const [individualLesson, setIndividualLesson] =
    useState<getLessonById_getLessonById | null>(null);
  const [isModalFreemiun, setIsModalFreemiun] = useState(false);
  useState<getLessonById_getLessonById | null>(null);
  const [modalDelete, setModalDelete] = useState<boolean>(false);
  const [showAssignClassroomLesson, setShowAssignClassroomLesson] =
    useState<boolean>(false);
  const [showAssignHomework, setShowAssignHomework] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectPanel, setSelectPanel] = useState<string>("1");
  const playerRef = useRef<ReactPlayer | null>(null);

  const isAllowRol =
    userToken?.role === "teacher" || userToken?.role === "coordinator";
  const reassingHomeworkButtonText = hasDatePassed(videoLessonEndDate)
    ? "Volver a planear"
    : "Editar planeación";

  const handleFullScreen = () => {
    setTimeout(() => {
      const playerElement = playerRef.current?.getInternalPlayer();

      if (playerElement) {
        if (playerElement.requestFullscreen) {
          playerElement.requestFullscreen();
        } else if ((playerElement as any).webkitRequestFullscreen) {
          (playerElement as any).webkitRequestFullscreen();
        } else if ((playerElement as any).msRequestFullscreen) {
          (playerElement as any).msRequestFullscreen();
        }
      }
    }, 150);
  };

  const showAssignHomeworkDrawer = () => {
    setShowAssignHomework(true);
  };
  const showAssignClassroomLessonDrawer = () => {
    setShowAssignClassroomLesson(true);
  };

  const onCloseFreemiun = () => {
    setIsModalFreemiun(false);
  };
  const reassingVideoLessonButtonText = () => {
    if (
      videoLessonStatusDate === "Hoy" ||
      videoLessonStatusDate === "Planeada" ||
      videoLessonStatusDate === "Esta Semana"
    ) {
      return "Editar planeación";
    } else {
      return "Volver a planear";
    }
  };

  const onCloseAssignHomeworkDrawer = () => {
    setShowAssignHomework(false);
  };
  const onCloseAndDeleteParams = () => {
    onClose();
    setSearchParams((prevParams) => {
      const params = new URLSearchParams(prevParams);
      params.delete("taskId");

      return params.toString();
    });
  };

  const onCloseModalDelete = () => {
    setModalDelete(false);
  };
  const deleteHomeworkModal = async () => {
    try {
      await deleteClassRoomLesson({ classroomLessonId: taskId!.toString() });
      NotificationPositive("¡Estupendo! La tarea ha sido eliminada con éxito.");
    } catch (error) {
      notificationNegative();
    }
    dispatch(setDependencyRefresh(!dependencyRefresh)); // Hacer Refresh

    setModalDelete(false);
    onClose();
  };

  const onCloseAssignClassroomLessonDrawer = () => {
    setShowAssignClassroomLesson(false);
  };

  const LISTENING_OPTIONS: Record<
    string,
    { name: string; audioFile: string | undefined | null }
  > = {
    "22": {
      name: "Song",
      audioFile: individualLesson?.lesson_content.listening?.song?.audio_file,
    },
    "23": {
      name: "Listening1",
      audioFile:
        individualLesson?.lesson_content.listening?.listening1?.audio_file,
    },
    "24": {
      name: "Listening2",
      audioFile:
        individualLesson?.lesson_content.listening?.listening2?.audio_file,
    },
    "25": {
      name: "Listening3",
      audioFile:
        individualLesson?.lesson_content.listening?.listening3?.audio_file,
    },
  };
  const returnStatusListening = (e: string) =>
    LISTENING_OPTIONS[e]?.audioFile || "";
  const returnNameListening = (e: string) => LISTENING_OPTIONS[e]?.name || "";

  const downloadPdf = async (pdfUrl: string, title: string) => {
    try {
      const response = await fetch(pdfUrl);
      if (!response.ok) {
        throw new Error("Error al descargar el archivo");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = title;
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url); // Limpia la memoria
    } catch (error) {
      console.error("Error al descargar el archivo:", error);
    }
  };

  const handleDownloadListening = async () => {
    try {
      const response = await fetch(returnStatusListening(selectPanel) ?? "");
      if (!response.ok) throw new Error("No se pudo descargar el archivo");

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${individualLesson?.title}_${returnNameListening(
        selectPanel
      )}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url); // Liberar el objeto URL después de la descarga
    } catch (error) {
      console.error("Error al descargar el archivo:", error);
    }
  };
  // Leccion Individual
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        let individualLessonRes: getLessonById_getLessonById | null = null;
        if (individualLessonID?.id) {
          individualLessonRes = await getLessonsById({
            lessonId: `${individualLessonID?.id}`,
          });
        } else if (lessonIdParams) {
          individualLessonRes = await getLessonsById({
            lessonId: `${lessonIdParams}`,
          });
        } else {
          individualLessonRes = await getLessonsById({
            lessonId: `${lessonId}`,
          });
        }
        setIndividualLesson(individualLessonRes);
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    })();
  }, [getLessonsById, individualLessonID]);

  useEffect(() => {
    (async () => {
      const classRoomRes = await getClassroomsByTeacher({
        getClassroomsByTeacherIdInput: {
          searchArgs: null,
        },
      });
      TeacherClassroooms.setTeacherClassrooms(classRoomRes);
    })();
  }, [getClassroomsByTeacher]);

  return (
    <>
      <Drawer
        placement={isMobile ? "bottom" : "right"}
        width={500}
        onClose={onCloseAndDeleteParams}
        className="drawerInstructions"
        open={visible}
        height={isMobile ? "500px" : ""}
        closable={false}
        style={{
          background: "white !important",
        }}
        title={
          isLoading ? (
            <ActivityIndicator />
          ) : (
            <TitleLessonNew
              individualLesson={individualLesson}
              onClose={onClose}
            />
          )
        }
        footerStyle={{ zIndex: 100 }}
        footer={
          isLoading ? (
            <ActivityIndicator />
          ) : (
            <div className={styles.footer}>
              {back === "calendar" ? (
                <>
                  {!isMobile && (
                    <img
                      src={deleteIcon}
                      alt="deleteIcon"
                      onClick={() => {
                        setModalDelete(true);
                      }}
                    />
                  )}
                  <div className={styles.assignFooterButtons}>
                    <button
                      className={styles.alumnoButton}
                      onClick={() => {
                        setShowAssignClassroomLesson(true);
                      }}
                    >
                      {videoLessonStatusDate &&
                      videoLessonStatusDate?.length > 0
                        ? reassingVideoLessonButtonText()
                        : reassingHomeworkButtonText}{" "}
                    </button>
                    {individualLesson?.lesson_type !== "listening" && (
                      <Link
                        className={styles.taskButton}
                        to={`/task/${individualLesson?.id}`}
                        state={{
                          item: location.state?.item ?? null,
                          lesson: individualLesson,
                          from: "homework",
                          openLessonDrawer: true,
                          back: back,
                          onNavigate: true,
                        }}
                      >
                        Empezar lección
                      </Link>
                    )}
                  </div>
                </>
              ) : (
                <>
                  {(individualLesson?.lesson_type === "classroomguide" ||
                    individualLesson?.lesson_type ===
                      "manipulative_dynamics") && (
                    <div className={styles.assignFooterButtonsClassroomGuide}>
                      {userToken?.schools[0].name == "Escuela Demo Prof" ? (
                        <a
                          onClick={() => setIsModalFreemiun(true)}
                          className={styles.taskButton}
                        >
                          Descargar Orientación
                        </a>
                      ) : (
                        <a
                          onClick={() => {
                            if (
                              userToken?.schools[0].name === "Escuela Demo Prof"
                            ) {
                              setIsModalFreemiun(true);
                            } else {
                              downloadPdf(
                                `${environment.awsBucketImages}pdf/${individualLesson?.lesson_content?.pdf}`,
                                `${individualLesson.title}_Orientacion didactica`
                              );
                              downloadOD({
                                contentId: Number(individualLesson.id),
                              });
                            }
                          }}
                          className={styles.taskButton}
                          target="_blank"
                          rel="noreferrer"
                          href={`${environment.awsBucketImages}pdf/${individualLesson?.lesson_content?.pdf}`}
                        >
                          Descargar Orientación
                        </a>
                      )}
                    </div>
                  )}
                  {individualLesson?.lesson_type === "evaluation" && (
                    <div className={styles.assignFooterButtonsClassroomGuide}>
                      <Link
                        className={
                          userToken?.role === "teacher" ||
                          userToken?.role === "coordinator"
                            ? styles.alumnoButton
                            : styles.taskButton
                        }
                        to={`/task/${individualLesson?.id}`}
                      >
                        {userToken?.role === "teacher" ||
                        userToken?.role === "coordinator"
                          ? "Ver evaluación"
                          : "Empezar"}
                      </Link>
                      {(userToken?.role === "teacher" ||
                        userToken?.role === "coordinator") && (
                        <button
                          className={styles.taskButton}
                          onClick={() => {
                            userToken?.schools[0].name === "Escuela Demo Prof"
                              ? setIsModalFreemiun(true)
                              : showAssignHomeworkDrawer &&
                                showAssignHomeworkDrawer();
                          }}
                        >
                          Asignar evaluación
                        </button>
                      )}
                    </div>
                  )}
                  {individualLesson?.lesson_type === "audio_exam" && (
                    <div
                      className={
                        selectPanel === "4" || selectPanel === "1"
                          ? styles.assignFooterButtonsPlayAudios
                          : styles.assignFooterButtons
                      }
                    >
                      <button
                        className={
                          selectPanel === "4" || selectPanel === "1"
                            ? styles.taskButtonRigth
                            : styles.alumnoButton
                        }
                        onClick={() => {
                          setSelectPanel("4"); // Cambia a la pestaña del reproductor
                          handleFullScreen();
                          isNem
                            ? ClickSeeAsStudent({
                                contentId: Number(individualLesson?.id),
                              })
                            : ClickSeeAsStudentNotNEM({
                                contentId: Number(individualLesson?.id),
                              });
                        }}
                      >
                        {userToken?.role === "teacher" ||
                        userToken?.role === "coordinator"
                          ? "Reproducir audio"
                          : "Empezar"}
                      </button>
                    </div>
                  )}
                  {(individualLesson?.lesson_type === "book_pages" ||
                    individualLesson?.lesson_type === "writing_exam" ||
                    individualLesson?.lesson_type === "audio_exam") && (
                    <div
                      className={styles.assignFooterButtonsClassroomGuide}
                      style={
                        individualLesson?.lesson_type === "audio_exam" &&
                        (selectPanel === "4" || selectPanel === "1")
                          ? { display: "none" }
                          : undefined
                      }
                    >
                      {userToken?.schools[0].name === "Escuela Demo Prof" ? (
                        <a
                          onClick={() => setIsModalFreemiun(true)}
                          className={styles.taskButton}
                        >
                          Descargar Página del libro
                        </a>
                      ) : selectPanel === "9" ? (
                        <a
                          onClick={() => {
                            downloadPdf(
                              `${environment.awsBucketImages}pdf/${individualLesson?.lesson_content?.responses_pdf}`,
                              `${individualLesson.title}_Libro maestro`
                            );
                            (userToken?.role === "teacher" ||
                              userToken?.role === "coordinator") &&
                              downloadBookPages({
                                contentId: Number(individualLesson?.id),
                              });
                            userToken?.role === "student" &&
                              studentActionsLibrary({
                                action: "descargar",
                                lessonId: individualLesson?.id
                                  ? individualLesson?.id
                                  : null,
                                section:
                                  back === "nem"
                                    ? "NEM"
                                    : back === "2017"
                                    ? "2017"
                                    : "2425",
                                typeLesson: "libro",
                              });
                          }}
                          className={styles.taskButton}
                          target="_blank"
                          rel="noreferrer"
                          href={`${environment.awsBucketImages}pdf/${individualLesson?.lesson_content?.responses_pdf}`}
                        >
                          Descargar páginas
                        </a>
                      ) : (
                        !(
                          (individualLesson?.lesson_type === "audio_exam" ||
                            individualLesson?.lesson_type === "writing_exam") &&
                          selectPanel !== "6"
                        ) && (
                          <a
                            onClick={() => {
                              const urlLocal =
                                individualLesson?.lesson_type === "book_pages"
                                  ? `${environment.awsBucketImages}pdf/${individualLesson?.lesson_content?.slide}`
                                  : `${environment.awsBucketImages}pdf/${individualLesson?.lesson_content?.pdf}`;

                              downloadPdf(
                                urlLocal,
                                `${individualLesson.title}_Libro alumno`
                              );
                              (userToken?.role === "teacher" ||
                                userToken?.role === "coordinator") &&
                                downloadBookPages({
                                  contentId: Number(individualLesson?.id),
                                });
                              userToken?.role === "student" &&
                                studentActionsLibrary({
                                  action: "descargar",
                                  lessonId: individualLesson?.id
                                    ? individualLesson?.id
                                    : null,
                                  section:
                                    back === "nem"
                                      ? "NEM"
                                      : back === "2017"
                                      ? "2017"
                                      : "2425",
                                  typeLesson: "libro",
                                });
                            }}
                            className={styles.taskButton}
                            target="_blank"
                            rel="noreferrer"
                            href={
                              individualLesson?.lesson_type === "book_pages"
                                ? `${environment.awsBucketImages}pdf/${individualLesson?.lesson_content?.slide}`
                                : `${environment.awsBucketImages}pdf/${individualLesson?.lesson_content?.pdf}`
                            }
                          >
                            {individualLesson?.lesson_type.includes("exam")
                              ? "Descargar examen"
                              : "Descargar páginas"}
                          </a>
                        )
                      )}
                    </div>
                  )}

                  {individualLesson?.lesson_type === "redaction" && (
                    <div className={styles.assignFooterButtonsClassroomGuide}>
                      {
                        <Link
                          className={styles.alumnoButton}
                          to={`/task/${individualLesson?.id}`}
                          onClick={() =>
                            isNem
                              ? ClickSeeAsStudent({
                                  contentId: Number(individualLesson?.id),
                                })
                              : ClickSeeAsStudentNotNEM({
                                  contentId: Number(individualLesson?.id),
                                })
                          }
                        >
                          {userToken?.role === "teacher" ||
                          userToken?.role === "coordinator"
                            ? "Ver lección"
                            : "Empezar"}
                        </Link>
                      }
                      {(userToken?.role === "teacher" ||
                        userToken?.role === "coordinator") && (
                        <a
                          onClick={showAssignHomeworkDrawer}
                          className={styles.taskButton}
                        >
                          Asignar tarea
                        </a>
                      )}
                    </div>
                  )}

                  {individualLesson?.lesson_type === "literacy" && (
                    <>
                      {individualLesson?.lesson_content?.pdf ||
                      individualLesson?.lesson_content?.slide ? (
                        <>
                          <Link
                            className={styles.alumnoButton}
                            to={`/task/${individualLesson?.id}`}
                            onClick={() =>
                              isNem
                                ? ClickSeeAsStudent({
                                    contentId: Number(individualLesson?.id),
                                  })
                                : ClickSeeAsStudentNotNEM({
                                    contentId: Number(individualLesson?.id),
                                  })
                            }
                          >
                            {userToken?.role === "teacher" ||
                            userToken?.role === "coordinator"
                              ? "Ver lectura"
                              : "Empezar"}
                          </Link>
                          <div
                            className={styles.assignFooterButtonsClassroomGuide}
                          >
                            <a
                              className={styles.taskButton}
                              target="_blank"
                              rel="noreferrer"
                              onClick={() => {
                                const urlLocal =
                                  selectPanel === "11"
                                    ? `${environment.awsBucketImages}pdf/${individualLesson?.lesson_content?.slide}`
                                    : `${environment.awsBucketImages}pdf/${individualLesson?.lesson_content?.pdf}`;
                                const rol =
                                  selectPanel === "11"
                                    ? `Lectura`
                                    : `Actividad`;
                                console.log(
                                  urlLocal,
                                  `${individualLesson.title}_${rol}`
                                );
                                downloadPdf(
                                  urlLocal,
                                  `${individualLesson.title}_${rol}`
                                );
                              }}
                              href={
                                selectPanel === "11"
                                  ? `${environment.awsBucketImages}pdf/${individualLesson?.lesson_content?.slide}`
                                  : `${environment.awsBucketImages}pdf/${individualLesson?.lesson_content?.pdf}`
                              }
                            >
                              {selectPanel === "11"
                                ? "Descargar Lectura"
                                : "Descargar Actividad"}
                            </a>

                            {(userToken?.role === "teacher" ||
                              userToken?.role === "coordinator") && (
                              <a
                                onClick={showAssignHomeworkDrawer}
                                className={styles.taskButton}
                              >
                                Asignar lectura
                              </a>
                            )}
                          </div>
                        </>
                      ) : (
                        <div
                          className={styles.assignFooterButtonsClassroomGuide}
                        >
                          <Link
                            className={styles.alumnoButton}
                            to={`/task/${individualLesson?.id}`}
                            onClick={() =>
                              isNem
                                ? ClickSeeAsStudent({
                                    contentId: Number(individualLesson?.id),
                                  })
                                : ClickSeeAsStudentNotNEM({
                                    contentId: Number(individualLesson?.id),
                                  })
                            }
                          >
                            {userToken?.role === "teacher" ||
                            userToken?.role === "coordinator"
                              ? "Ver lectura"
                              : "Empezar"}
                          </Link>
                          {(userToken?.role === "teacher" ||
                            userToken?.role === "coordinator") && (
                            <a
                              onClick={showAssignHomeworkDrawer}
                              className={styles.taskButton}
                            >
                              Asignar lectura
                            </a>
                          )}
                        </div>
                      )}
                    </>
                  )}

                  {individualLesson?.lesson_type === "slides" && (
                    <div className={styles.assignFooterButtonsClassroomGuide}>
                      {(userToken?.role === "teacher" ||
                        userToken?.role === "coordinator") &&
                        individualLesson?.drive_link && (
                          <a
                            className={styles.alumnoButton}
                            target="_blank"
                            href={`${individualLesson.drive_link}`}
                            rel="noreferrer"
                            onClick={() =>
                              (userToken?.role === "teacher" ||
                                userToken?.role === "coordinator") &&
                              editSlidesRegisterEvent({
                                contentId: Number(individualLesson.id),
                              })
                            }
                          >
                            Editar Diapositivas
                          </a>
                        )}
                      {userToken?.schools[0].name === "Escuela Demo Prof" ? (
                        <a
                          onClick={() => setIsModalFreemiun(true)}
                          className={styles.taskButton}
                        >
                          Descargar Diapositivas
                        </a>
                      ) : (
                        <a
                          onClick={() => {
                            downloadPdf(
                              `${environment.awsBucketImages}pdf/${individualLesson?.lesson_content?.slide}`,
                              `${individualLesson.title}_Diapositivas`
                            );
                            (userToken?.role === "teacher" ||
                              userToken?.role === "coordinator") &&
                              downloadSlides({
                                contentId: Number(individualLesson?.id),
                              });
                            userToken?.role === "student" &&
                              studentActionsLibrary({
                                action: "descargar",

                                lessonId: individualLesson?.id
                                  ? individualLesson?.id
                                  : null,
                                section:
                                  back === "nem"
                                    ? "NEM"
                                    : back === "2017"
                                    ? "2017"
                                    : "2425",
                                typeLesson: "diapos",
                              });
                          }}
                          className={styles.taskButton}
                          target="_blank"
                          rel="noreferrer"
                          href={`${environment.awsBucketImages}pdf/${individualLesson?.lesson_content?.slide}`}
                        >
                          Descargar Diapositivas
                        </a>
                      )}
                    </div>
                  )}
                  {individualLesson?.lesson_type === "listening" &&
                    isAllowRol && (
                      <div className={styles.assignFooterButtonsClassroomGuide}>
                        <div
                          className={styles.alumnoButton}
                          onClick={showAssignClassroomLessonDrawer}
                        >
                          Plan Lesson
                        </div>
                        <button
                          onClick={handleDownloadListening}
                          className={styles.taskButton}
                        >
                          Download Audio
                        </button>
                      </div>
                    )}

                  {(individualLesson?.lesson_type === "videolesson" ||
                    individualLesson?.lesson_type === "audio" ||
                    individualLesson?.lesson_type === "conversation") && (
                    <>
                      <div className={styles.containerbuttonFooter}>
                        <Link
                          className={
                            userToken?.role === "teacher" ||
                            userToken?.role === "coordinator"
                              ? styles.alumnoButton
                              : styles.alumnoButtonAmarillo
                          }
                          to={`/task/${individualLesson?.id}`}
                          onClick={() =>
                            isNem
                              ? ClickSeeAsStudent({
                                  contentId: Number(individualLesson?.id),
                                })
                              : ClickSeeAsStudentNotNEM({
                                  contentId: Number(individualLesson?.id),
                                })
                          }
                        >
                          {userToken?.role === "teacher" ||
                          userToken?.role === "coordinator"
                            ? "Ver lección"
                            : "Empezar"}
                        </Link>

                        {(userToken?.role === "teacher" ||
                          userToken?.role === "coordinator") &&
                          individualLesson?.lesson_type !== "conversation" && (
                            <button
                              className={styles.alumnoButton}
                              onClick={() => {
                                userToken?.schools[0].name ===
                                "Escuela Demo Prof"
                                  ? setIsModalFreemiun(true)
                                  : showAssignClassroomLessonDrawer &&
                                    showAssignClassroomLessonDrawer();
                              }}
                            >
                              Planear Lección
                            </button>
                          )}
                      </div>
                      {isAllowRol && (
                        <button
                          className={styles.taskButtonVideoLesson}
                          onClick={() => {
                            userToken?.schools[0].name === "Escuela Demo Prof"
                              ? setIsModalFreemiun(true)
                              : showAssignHomeworkDrawer &&
                                showAssignHomeworkDrawer();
                          }}
                        >
                          Asignar tarea
                        </button>
                      )}
                    </>
                  )}
                </>
              )}
              {isModalFreemiun && (
                <ModalFreemiun
                  open={isModalFreemiun}
                  onClose={onCloseFreemiun}
                ></ModalFreemiun>
              )}
            </div>
          )
        }
      >
        {visible && (
          <MainLesson
            individualLesson={individualLesson}
            back={back}
            playerRef={playerRef}
            selectPanel={selectPanel}
            setSelectPanel={setSelectPanel}
          />
        )}

        <ModalDelete
          modalDelete={modalDelete}
          onCloseModalDelete={onCloseModalDelete}
          deleteHomeworkModal={deleteHomeworkModal}
        ></ModalDelete>
      </Drawer>

      {showAssignHomework && (
        <AssingHomeworkDrawer
          visible={showAssignHomework}
          onClose={onCloseAssignHomeworkDrawer}
          incomingSelectedLesson={individualLesson}
        />
      )}

      {showAssignClassroomLesson && (
        <AssingClassroomLesson
          setIsClassroomLessonsModalVisible={setShowAssignClassroomLesson}
          visible={showAssignClassroomLesson}
          onClose={onCloseAssignClassroomLessonDrawer}
          incomingSelectedLesson={individualLesson}
          taskId={taskId ?? ""}
        />
      )}
    </>
  );
};

export default DrawerLessonNew;
