import React, { FC } from "react";
import { Modal } from "antd";
import styles from "./modalReward.module.scss";
import gem from "../../../assets/pages/store/gem.png";
import medalFirst from "../../../assets/icons/clasification/medalFirst.svg";
import medalThired from "../../../assets/icons/clasification/medalThired.svg";
import medalSecond from "../../../assets/icons/clasification/medalSecond.svg";
import useIsMobile from "../../../hooks/useIsMobile";
import useCloseFinishTorunament from "../../../api/useCloseFinishTournamentModal";
import { getUserByEmail_getUserByEmail } from "../../../__generated__/gql-types/getUserByEmail";
import { RewardTeacher } from "./RewardTeacher";
import useAuth from "../../../context/useAuth";

export interface TrophiesTournament {
  position: number;
  score: number;
  range_date: string;
}

interface PropsModalReward {
  openModal: boolean;
  userScore: getUserByEmail_getUserByEmail | null;
  onCloseModalPosition: () => void;
}

const ModalReward: FC<PropsModalReward> = ({
  openModal,
  onCloseModalPosition,
  userScore,
}) => {
  const closeFinish = useCloseFinishTorunament();
  const isMobile = useIsMobile();
  const { userToken } = useAuth();
  const lastTrophyPosition =
    userScore?.avatar_state?.trophies?.at(-1)?.position;
  const isTopPosition =
    typeof lastTrophyPosition == "number" && lastTrophyPosition < 3
      ? true
      : false;

  const positionData: {
    [key: number]: {
      reward: number;
      medalImage: string;
      message: string;
    };
  } = {
    1: {
      reward: 500,
      medalImage: medalFirst,
      message: "Has terminado el torneo en primera posición",
    },
    2: {
      reward: 300,
      medalImage: medalSecond,
      message: "Has terminado el torneo en segunda posición",
    },
    3: {
      reward: 200,
      medalImage: medalThired,
      message: "Has terminado el torneo en tercera posición",
    },
  };

  return (
    <Modal
      open={openModal}
      closable={userToken?.role === "teacher" ? true : false}
      width={
        isMobile ? "100%" : userToken?.role === "teacher" ? "600px" : "800px"
      }
      footer={""}
      onCancel={onCloseModalPosition}
      className={`${styles.container} modalReward`}
      centered
    >
      {userToken?.role === "teacher" ? (
        <RewardTeacher onCloseModalPosition={onCloseModalPosition} />
      ) : (
        lastTrophyPosition && (
          <div className={styles.content}>
            {isTopPosition ? (
              <>
                <img
                  src={positionData[lastTrophyPosition].medalImage}
                  alt="medal"
                  className={styles.medalImg}
                />
                <div className={styles.description}>
                  {positionData[lastTrophyPosition].message}
                </div>{" "}
                <div
                  className={styles.buttonReward}
                  onClick={() => {
                    onCloseModalPosition();
                    closeFinish();
                  }}
                >
                  <div className={styles.reclaim}>Reclama tu recompensa</div>
                  <img src={gem} alt="asd"></img>
                  <div>{positionData[lastTrophyPosition].reward}</div>
                </div>
              </>
            ) : (
              <>
                <div className={styles.containerFinishTournement}>
                  El torneo quincenal ha terminado
                </div>
                <div className={styles.description}>
                  Has terminado el torneo en {lastTrophyPosition}° posición.
                  <br />
                  <br />
                  ¡Sigue realizando misiones para mejorar tu resultado en el
                  nuevo torneo!
                </div>{" "}
                <div
                  className={styles.buttonReward}
                  onClick={() => {
                    onCloseModalPosition();
                    closeFinish();
                  }}
                >
                  <div className={styles.reclaim} style={{ margin: 0 }}>
                    IR A MISIONES
                  </div>
                </div>
              </>
            )}
          </div>
        )
      )}
    </Modal>
  );
};

export default ModalReward;
