import { useState, useEffect } from "react";

const useIsHorizontalMobile = () => {
  const [isHorizontalMobile, setIsHorizontalMobile] = useState(false);

  const handleResize = () => {
    setIsHorizontalMobile(window.innerHeight < 490); // Cambia el valor 768 según tus necesidades
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return isHorizontalMobile;
};

export default useIsHorizontalMobile;
