import { useCallback } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { regenerateTeacherProjectVariables } from "../__generated__/gql-types/regenerateTeacherProject";

const UseRegenerateTeacherProject = () => {
  const client = useApolloClient();

  return useCallback(
    async (id: regenerateTeacherProjectVariables) => {
      return await client.mutate({
        mutation: REGENERATE_PROJECT,
        fetchPolicy: "no-cache",
        variables: id,
      });
    },
    [client]
  );
};
export default UseRegenerateTeacherProject;

const REGENERATE_PROJECT = gql`
  mutation regenerateTeacherProject($teacherProjectId: Int) {
    regenerateTeacherProject(teacherProject_id: $teacherProjectId)
  }
`;
