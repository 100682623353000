import React, { useEffect, useState } from "react";
import styles from "./store.module.scss";
import { getUserScore_getUserScore } from "../../__generated__/gql-types/getUserScore";
import UseUserScore from "../../api/useGetUserScore";
import { ColumnAvatar } from "./ColumnAvatar";
import { ColumnStore } from "./ColumnStore";
import UseGetClothesAndAccessories from "../../api/useGetClothesAndAccessories";
import { getClothesAndAccessories_getClothesAndAccessories as PropsClothes } from "../../__generated__/gql-types/getClothesAndAccessories";
import { Skeleton } from "antd";
import useIsMobile from "../../hooks/useIsMobile";
import { StoreMobile } from "./StoreMobile";
import Championship from "./Championship/Championship";

const Store = () => {
  const getUserScore = UseUserScore();
  const getClothesAndAccessories = UseGetClothesAndAccessories();
  const isMobile = useIsMobile();
  const [section, setSection] = useState("points");
  const [isLoading, setIsLoading] = useState(false);
  const [userScore, setUserScore] = useState<getUserScore_getUserScore>();

  const [clothesAndAccessories, setClothesAndAccessories] = useState<
    PropsClothes[] | null
  >(null);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await getClothesAndAccessories();
      setClothesAndAccessories(response);
      setIsLoading(false);
    })();
  }, [getClothesAndAccessories]);

  useEffect(() => {
    (async () => {
      const userScoreRes = await getUserScore();
      userScoreRes && setUserScore(userScoreRes);
    })();
  }, [getUserScore]);

  return isLoading ? (
    <div className={styles.spinner}>
      <Skeleton active paragraph={{ rows: 1 }} />
      <Skeleton active paragraph={{ rows: 1 }} />
      <Skeleton active paragraph={{ rows: 5 }} />
      <Skeleton active paragraph={{ rows: 1 }} />
      <Skeleton active paragraph={{ rows: 7 }} />
    </div>
  ) : isMobile ? (
    <StoreMobile
      clothesAndAccessories={clothesAndAccessories}
      userScore={userScore}
      setClothesAndAccessories={setClothesAndAccessories}
      setUserScore={setUserScore}
    />
  ) : (
    <div
      className={styles.container}
      style={
        section === "championship"
          ? { minHeight: "100dvh" }
          : { height: "calc(100dvh - 96px)" }
      }
    >
      <div className={styles.containerHeader}>
        <div
          onClick={() => setSection("points")}
          className={
            section === "points"
              ? styles.buttonSelected
              : styles.buttonDeselected
          }
        >
          PUNTOS
        </div>
        <div
          onClick={() => setSection("championship")}
          className={
            section === "championship"
              ? styles.buttonSelected
              : styles.buttonDeselected
          }
        >
          TORNEO
        </div>
      </div>

      {section === "points" && (
        <div className={styles.content}>
          <ColumnAvatar
            userScore={userScore}
            clothesAndAccessories={clothesAndAccessories}
          />

          <ColumnStore
            setClothesAndAccessories={setClothesAndAccessories}
            userScore={userScore}
            setUserScore={setUserScore}
            clothesAndAccessories={clothesAndAccessories}
          ></ColumnStore>
        </div>
      )}
      {section === "championship" && <Championship />}
    </div>
  );
};

export default Store;
