import { configureStore } from "@reduxjs/toolkit";
import tokens from "../slices/tokens";
import uploadedFiles from "../slices/uploadedFiles";
import dependencyRefresh from "../slices/dependencyRefresh";

export const store = configureStore({
  reducer: { tokens, uploadedFiles, dependencyRefresh },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const Tokens = (state: RootState) => state.tokens;
export const DependencyRefresh = (state: RootState) => state.dependencyRefresh;
export const emailOrUsername = (state: RootState) =>
  state.tokens.emailOrUsername;
export const UploadedFiles = (state: RootState) => state.uploadedFiles;
