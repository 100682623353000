import styles from "./tableClasification.module.scss";
import arrowUp from "../../../../assets/icons/clasification/arrowUp.svg";
import arrowDown from "../../../../assets/icons/clasification/arrowDown.svg";
import equal from "../../../../assets/icons/clasification/equal.svg";
import { resolveAvatar, resolveColorAvatar } from "../../../../utils/functions";
import { functionReturnImgAvatar } from "../../../Store/ColumnAvatar/ContainerAvatar/utils";
import {
  getInfoTournament_getInfoTournament,
  getInfoTournament_getInfoTournament_userInfo,
} from "../../../../__generated__/gql-types/getInfoTournament";
import useAuth from "../../../../context/useAuth";
type StateType = "equal" | "up" | "bottom";
interface PropsBanner {
  infoTournament: getInfoTournament_getInfoTournament | null;
  setUserModal: (e: getInfoTournament_getInfoTournament_userInfo) => void;
  setOpenModal: (e: boolean) => void;
}

const TableClasification = ({
  infoTournament,
  setUserModal,
  setOpenModal,
}: PropsBanner) => {
  const { userToken } = useAuth();

  const icons = {
    equal: equal,
    up: arrowUp,
    top: arrowUp,
    bottom: arrowDown,
  };

  return (
    <div className={styles.cardsContainer}>
      {infoTournament &&
        infoTournament?.userInfo?.map(
          (student: getInfoTournament_getInfoTournament_userInfo) => {
            if (
              student.position !== 0 &&
              student.position !== 1 &&
              student.position !== 2
            ) {
              let backgroundColor = resolveColorAvatar(
                student.avatar_name ?? "polux"
              );
              return (
                <div
                  className={styles.cardContainer}
                  key={student.position}
                  onClick={() => {
                    setUserModal(student);
                    setOpenModal(true);
                  }}
                >
                  <div className={styles.numberContainer}>
                    {icons[student.movement as StateType] && (
                      <img src={icons[student.movement as StateType]} alt="" />
                    )}
                    <div> {student.position && student.position + 1}</div>
                  </div>
                  <div
                    className={styles.textContainer}
                    style={
                      Number(userToken?.id) === student.id
                        ? { background: "#FFC95C", borderColor: "#FFC95C" }
                        : {}
                    }
                  >
                    <div className={styles.avatarContainer}>
                      <div
                        className={styles.studentAvatar}
                        style={{ background: `${backgroundColor}` }}
                      >
                        {student?.shopActions?.map((clothes: any) => (
                          <img
                            src={clothes.item_img ?? ""}
                            alt="avatar"
                            style={{
                              zIndex: clothes.z_index ? clothes.z_index * 2 : 0,
                            }}
                            className={styles.clothes}
                          />
                        ))}
                        <img
                          src={
                            student?.level && student?.level < 6
                              ? resolveAvatar(
                                  student?.avatar_name ?? "polux",
                                  student?.level.toString() ?? "1"
                                )
                              : functionReturnImgAvatar(
                                  student?.avatar_name ?? ""
                                )
                          }
                          alt="avatar"
                          className={
                            student?.level && student?.level < 6
                              ? styles.avatarImgwithOutLevel // revisar
                              : styles.avatarImg
                          }
                        />
                      </div>
                      <div className={styles.textName}>{student.name}</div>
                    </div>
                    <div className={styles.textPoint}>
                      {student.score ?? 0}{" "}
                      <span className={styles.textPointHidden}> puntos</span>
                    </div>
                  </div>
                </div>
              );
            } else {
              return null;
            }
          }
        )}
    </div>
  );
};

export default TableClasification;
