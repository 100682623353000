import React, { FC, useEffect, useState } from "react";
import styles from "./modal.module.scss";
import { Modal } from "antd";
import useIsMobile from "../../../hooks/useIsMobile";
import lucaBot from "./../../../assets/icons/botNotification.png";
import useGetLastNotification from "../../../api/useGetLastNotification";
import { getLastNotification_getLastNotification as PropsNotification } from "../../../__generated__/gql-types/getLastNotification";
import useUpdateShowNotification from "../../../api/useUpdateNotification";
interface ModalProps {
  openModal: boolean;
  setNotification: (e: boolean) => void;
}
const ModalNotification: FC<ModalProps> = ({ openModal, setNotification }) => {
  const isMobile = useIsMobile();
  const getLastNotifiction = useGetLastNotification();
  const updateNotification = useUpdateShowNotification();

  const [dataNotification, setDataNotification] =
    useState<PropsNotification | null>();

  const onCloseModalPosition = () => {
    setNotification(false);
    updateNotification({ value: false });
  };
  useEffect(() => {
    (async () => {
      const response = await getLastNotifiction();
      response && setDataNotification(response);
    })();
  }, [getLastNotifiction]);

  return (
    <Modal
      open={openModal}
      onCancel={onCloseModalPosition}
      closable={false}
      width={isMobile ? "90%" : "636px"}
      footer={""}
      styles={{ content: { borderRadius: "20px" } }}
      className={`${styles.container}`}
      centered
    >
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.contaienrText}>
            <div className={styles.title}> {dataNotification?.title}</div>
            <div className={styles.description}>
              {dataNotification?.message}
            </div>
            <div></div>
          </div>
          <img src={dataNotification?.url ?? lucaBot} alt="" />
        </div>
        <div className={styles.button} onClick={onCloseModalPosition}>
          {dataNotification?.role === "teacher"
            ? "Continuar"
            : "IR A MIS MISIONES"}
        </div>
      </div>
    </Modal>
  );
};

export default ModalNotification;
