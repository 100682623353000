import "moment/locale/es";
import { FC } from "react";
import styles from "./card.module.scss";
import moment from "moment";
import { Button } from "../../../../../Shared/Button";
import gemPositive from "../../../../../../assets/icons/gems/gemPositive.svg";
import gemNegative from "../../../../../../assets/icons/gems/gemNegative.svg";
import video1 from "../../../../../../assets/icons/videoMisionPending.svg";
import lectura1 from "../../../../../../assets/icons/lectura.svg";
import conversation from "../../../../../../assets/icons/conversation.png";
import { toLocalDate } from "../../../../../../utils/functions";
import { useLocation, useNavigate } from "react-router-dom";
import {
  encontrarValorMayor,
  functionReturnImg,
  functionReturnSubject,
  functionReturnTag,
  functionReturnTextALLCTA,
  functionReturnTextCTA,
} from "./utils";
import { getHomeworksByClassroom_getHomeworksByClassroom } from "../../../../../../__generated__/gql-types/getHomeworksByClassroom";
import { CTAButtons } from "../../../../../../__generated__/gql-types/globalTypes";

interface CardProps {
  homework: getHomeworksByClassroom_getHomeworksByClassroom | null;
  type?: string;
  setVisibleLesson: (e: boolean) => void;
  setIdHomework: (e: string) => void;
}

const Card: FC<CardProps> = ({
  homework,
  type,
  setVisibleLesson,
  setIdHomework,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const maxResult = encontrarValorMayor(
    homework?.result ?? null,
    homework?.post_homework_result ?? null
  );

  const showDrawerLesson = () => {
    setVisibleLesson(true);
    homework?.id && setIdHomework(homework?.id);
    const params = new URLSearchParams(location.search);
    homework?.id && params.set("homeworkId", homework?.id);
    navigate(`?${params.toString()}`);
  };

  return (
    <div className={styles.container}>
      {homework?.lessons?.[0]?.lesson_type === "redaction" &&
        !homework?.redaction_review_by_student &&
        homework?.redaction_correction && (
          <div className={styles.badgeRed}> !</div>
        )}

      <div className={styles.imageContainer}>
        <img
          src={functionReturnImg(homework)}
          alt="defaultImage"
          className={styles.imagenCard}
        />

        <div className={styles.subjectButton}>
          {homework?.lessons?.[0].subject_id &&
            functionReturnSubject(homework?.lessons?.[0].subject_id)}
        </div>
        <div className={styles.contentButton}>
          <img
            src={
              homework?.lessons &&
              homework?.lessons[0].lesson_type === "literacy"
                ? lectura1
                : homework?.lessons &&
                  homework?.lessons[0].lesson_type === "conversation"
                ? conversation
                : video1
            }
            alt="asd"
          />{" "}
          <span>
            {homework?.lessons?.[0].lesson_type &&
              homework?.lessons?.[0].subject_id &&
              functionReturnTag(
                homework?.lessons?.[0].lesson_type,
                homework?.lessons?.[0].subject_id
              )}
          </span>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.titleContainer}>
          <h1>{homework?.title}</h1>

          {type !== "approved" ||
          (!homework?.redaction_correction &&
            homework?.finished &&
            homework?.lessons?.[0].lesson_type === "redaction") ? (
            <div className={styles.finishContainer}>
              {`Finaliza   ${moment(
                toLocalDate(homework?.end_date)?.slice(0, 5),
                "DD-MM"
              ).format("D MMM")}`}
            </div>
          ) : (
            <div className={styles.containerGem}>
              {maxResult && maxResult >= 3 ? (
                <img src={gemPositive} alt="" />
              ) : (
                <img src={gemNegative} alt="" />
              )}
              {maxResult && maxResult >= 6 ? (
                <img src={gemPositive} alt="" />
              ) : (
                <img src={gemNegative} alt="" />
              )}
              {maxResult && maxResult > 9 ? (
                <img src={gemPositive} alt="" />
              ) : (
                <img src={gemNegative} alt="" />
              )}
            </div>
          )}
        </div>
        <div className={styles.botonContainer}>
          {homework?.lessons?.[0].lesson_type === "redaction" ? (
            <Button
              type={
                homework.cta_button === CTAButtons.NO_ENTREGADA
                  ? "secondary-large"
                  : "primary-large"
              }
              disabled={
                homework.cta_button === CTAButtons.NO_ENTREGADA ? true : false
              }
              text={
                homework.cta_button && homework?.lessons?.[0].subject_id
                  ? functionReturnTextCTA(
                      homework.cta_button,
                      homework?.lessons?.[0].subject_id
                    )
                  : "as"
              }
              onClick={() => {
                showDrawerLesson();
              }}
            />
          ) : homework?.lessons?.[0].lesson_type === "conversation" ? (
            <Button
              type="primary-large"
              text={maxResult === null ? "start" : "repeat"}
              onClick={() => {
                showDrawerLesson();
              }}
            />
          ) : (
            <Button
              type="primary-large"
              text={
                homework ? functionReturnTextALLCTA(homework, maxResult) : ""
              }
              onClick={() => {
                showDrawerLesson();
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
