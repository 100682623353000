import { FC, useState } from "react";
import { Card } from "../Card";
import styles from "./tabsComponent.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { getHomeworksByClassroom_getHomeworksByClassroom as StudentHomeworkProps } from "../../../../../../__generated__/gql-types/getHomeworksByClassroom";

interface TabsComponentProps {
  completedTasks: StudentHomeworkProps[] | null;
  needReviewTasks: StudentHomeworkProps[] | null;
  redactionTasks: StudentHomeworkProps[] | null;
  contReviewStudent: number;
  setIdHomework: (e: string) => void;
  setVisibleLesson: (e: boolean) => void;
}

const TabsComponent: FC<TabsComponentProps> = ({
  setIdHomework,
  setVisibleLesson,
  completedTasks,
  needReviewTasks,
  redactionTasks,
  contReviewStudent,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const tabParams = queryParams.get("tab");
  const [tabsKey, setTabsKey] = useState(
    tabParams ? tabParams : "completedTasks"
  );

  const setTabsInUrl = (tab: string) => {
    queryParams.set("tab", tab);
    queryParams.delete("homeworkId");
    navigate(`?${queryParams.toString()}`);
  };

  return (
    <div className={styles.container}>
      <div className={styles.containerTabs}>
        <div
          className={
            tabsKey === "completedTasks"
              ? styles.buttonTabSelect
              : styles.buttonTab
          }
          onClick={() => {
            setTabsInUrl("completedTasks");
            setTabsKey("completedTasks");
          }}
        >
          Misiones superadas
        </div>
        <div
          className={
            tabsKey === "needReviewTasks"
              ? styles.buttonTabSelect
              : styles.buttonTab
          }
          onClick={() => {
            setTabsInUrl("needReviewTasks");
            setTabsKey("needReviewTasks");
          }}
        >
          Necesita repasar
          {needReviewTasks && needReviewTasks?.length > 0 && (
            <div className={styles.badgeRed}> {needReviewTasks?.length}</div>
          )}
        </div>
        <div
          className={
            tabsKey === "redactionTasks"
              ? styles.buttonTabSelect
              : styles.buttonTab
          }
          onClick={() => {
            setTabsInUrl("redactionTasks");
            setTabsKey("redactionTasks");
          }}
        >
          Redacciones
          {contReviewStudent >= 1 && (
            <div className={styles.badgeRed}>{contReviewStudent}</div>
          )}
        </div>
      </div>
      <div className={styles.cardContainer}>
        {tabsKey === "completedTasks" &&
          completedTasks?.map((element, key) => {
            return (
              <Card
                homework={element}
                type="approved"
                key={key}
                setIdHomework={setIdHomework}
                setVisibleLesson={setVisibleLesson}
              />
            );
          })}
        {tabsKey === "needReviewTasks" &&
          needReviewTasks?.map((element, index) => {
            return (
              <Card
                homework={element}
                type="approved"
                key={index}
                setIdHomework={setIdHomework}
                setVisibleLesson={setVisibleLesson}
              />
            );
          })}
        {tabsKey === "redactionTasks" &&
          redactionTasks?.map((element, index) => {
            return (
              <Card
                homework={element}
                type="approved"
                key={index}
                setIdHomework={setIdHomework}
                setVisibleLesson={setVisibleLesson}
              />
            );
          })}
      </div>
    </div>
  );
};
export default TabsComponent;
