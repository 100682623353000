import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import { getLastNotification } from "../__generated__/gql-types/getLastNotification";

const useGetLastNotification = () => {
  const client = useApolloClient();

  return useCallback(async () => {
    const result: ApolloQueryResult<getLastNotification> = await client.query({
      query: GET_LAST_NOTIFICATION,
      fetchPolicy: "no-cache",
    });

    if (result.error) {
      throw new ApolloError({
        graphQLErrors: result.errors,
      });
    }

    return result.data.getLastNotification;
  }, [client]);
};

export default useGetLastNotification;

const GET_LAST_NOTIFICATION = gql`
  query getLastNotification {
    getLastNotification {
      id
      title
      message
      media
      role
      fileId
      url
      school_id
      created_at
      updated_at
      deleted_at
    }
  }
`;
