import { FC, useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import logo from "../../assets/icons/logo_blue.svg";
import styles from "./formTeacher.module.scss";
import { LoginFormSchema } from "./LoginForm.types";
import eyesSelect from "../../assets/images/eye-regular.svg";
import eyesDeselect from "../../assets/images/eye-slash-regular.svg";
import { City, State, ICity } from "country-state-city";
import {
  NotificationPositive,
  notificationNegative,
} from "../Shared/Notifacation/NotificationLuca";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { apiLuca } from "../../utils/constants";
import useAuth from "../../context/useAuth";

const FormTeacher: FC = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [stateFromMex, setStateFromMex] = useState("AGU");
  const [cities, setCities] = useState<ICity[]>();
  const [city, setCity] = useState<ICity>();
  const { logout } = useAuth();

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (values: any) => {
    const objValue = {
      userInput: {
        city: city?.name ?? "AGU",
        state: stateFromMex,
        password: values.password,
        name: values.nameTeacher,
        email: values.emailOrUsername,
        school: values.schoolTeacher,
        cct: values.cctTeacher,
        phone: values.phoneTeacher,
        grade: values.gradeTeacher ? Number(values.gradeTeacher) : 1,
      },
    };

    try {
      const { data } = await axios.post(`${apiLuca}/demoRegister`, objValue);
      console.log(objValue);
      console.log(data);
      NotificationPositive("¡Estupendo! La cuenta ha sido creada con éxito.");
      navigate("/");
    } catch (error) {
      notificationNegative();
    }
  };

  const stateData = State.getStatesOfCountry("MX").map((state) => ({
    value: state.name,
    isCode: state.isoCode,
    displayValue: state.name,
  }));

  const handleChange = (e: any) => {
    const selectedCity = e.target.value;
    setStateFromMex(selectedCity);
    const firstCity = City.getCitiesOfState("MX", selectedCity);
    setCity(firstCity[0]);
  };
  const handleChangeCities = (e: any) => {
    const city = e.target.value;
    setCity(city);
  };

  useEffect(() => {
    logout();
    setCities(City.getCitiesOfState("MX", stateFromMex));
  }, [stateFromMex]);

  return (
    <div className={styles.container}>
      <img src={logo} alt="" className={styles.logo} />

      <div className={styles.titleContainer}>
        <h3 className={styles.title}>
          ¡Estás a un paso de empezar tu experiencia en Luca y tener acceso a
          todo el contenido del programa oficial!
        </h3>

        <h3 className={styles.subTitle}>
          ¡Déjanos tus datos y en automático se crea tu cuenta!
        </h3>
      </div>

      <div className={styles.formContainer}>
        <Formik
          initialValues={{
            emailOrUsername: "",
            password: "",
          }}
          onSubmit={handleSubmit}
          validationSchema={LoginFormSchema}
        >
          <Form className={styles.formStyleCenter}>
            <h3 className={styles.formText}>Nombre *</h3>
            <Field
              id="nameTeacher"
              name="nameTeacher"
              className={styles.formFields}
            />
            <ErrorMessage
              name="nameTeacher"
              component="div"
              className={styles.error}
            />

            <h3 className={styles.formText}>Email *</h3>
            <Field
              id="emailOrUsername"
              name="emailOrUsername"
              className={styles.formFields}
            />
            <ErrorMessage
              name="emailOrUsername"
              component="div"
              className={styles.error}
            />
            <h3 className={styles.formText}>Escuela *</h3>
            <Field
              id="schoolTeacher"
              name="schoolTeacher"
              className={styles.formFields}
            />
            <ErrorMessage
              name="schoolTeacher"
              component="div"
              className={styles.error}
            />

            <h3 className={styles.formText}>
              Clave de Centro de Trabajo (CCT){" "}
              <span className={styles.formTextOpcional}>Opcional</span>
            </h3>

            <Field
              id="cctTeacher"
              name="cctTeacher"
              className={styles.formFields}
            />
            <h3 className={styles.formText}>Telefono *</h3>
            <Field
              id="phoneTeacher"
              name="phoneTeacher"
              className={styles.formFields}
            />
            <ErrorMessage
              name="phoneTeacher"
              component="div"
              className={styles.error}
            />
            <h3 className={styles.formText}>Grado *</h3>
            <Field
              as="select"
              name="gradeTeacher"
              className={styles.formFields}
            >
              <option value="1">1° Primaria </option>
              <option value="2">2° Primaria</option>
              <option value="3">3° Primaria</option>
              <option value="4">4° Primaria</option>
              <option value="5">5° Primaria</option>
              <option value="6">6° Primaria</option>
            </Field>

            <ErrorMessage
              name="gradeTeacher"
              component="div"
              className={styles.error}
            />
            <h3 className={styles.formText}>Estados *</h3>
            <Field
              as="select"
              name="cityTeacher"
              className={styles.formFields}
              onChange={handleChange}
            >
              {stateData.map((e) => {
                return <option value={e.isCode}>{e.value}</option>;
              })}
            </Field>

            <ErrorMessage
              name="cityTeacher"
              component="div"
              className={styles.error}
            />
            <h3 className={styles.formText}>Ciudad *</h3>
            <Field
              as="select"
              name="cityTeacher"
              className={styles.formFields}
              onChange={handleChangeCities}
            >
              {cities?.map((e) => (
                <option value={e.name}>{e.name}</option>
              ))}
            </Field>

            <ErrorMessage
              name="cityTeacher"
              component="div"
              className={styles.error}
            />

            <h3 className={styles.formText}>Contraseña *</h3>
            <div className={styles.containerPassword}>
              <Field
                id="password"
                name="password"
                className={styles.formFields}
                type={showPassword ? "text" : "password"}
              />
              <div
                className={styles.containerEye}
                onClick={handleTogglePassword}
              >
                {showPassword ? (
                  <img
                    src={eyesSelect}
                    alt="mostrar"
                    className={styles.eyeSelect}
                  />
                ) : (
                  <img
                    src={eyesDeselect}
                    alt="mostrar"
                    className={styles.eyeSelect}
                    style={{ width: "22px" }}
                  />
                )}
              </div>
            </div>

            <ErrorMessage
              name="password"
              component="div"
              className={styles.error}
            />

            <button type="submit" className={styles.submitButton}>
              Crear Cuenta
            </button>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default FormTeacher;
