import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import styles from "./layout.module.scss";
import { useLocation } from "react-router-dom";
import SiderLuca from "./Sider/SiderLuca";
import { SelectAvatar } from "../Students/SelectAvatar";
import useIsMobile from "../../hooks/useIsMobile";
import { ModalReward } from "./ModalReward";
import { getUserByEmail_getUserByEmail } from "../../__generated__/gql-types/getUserByEmail";
import useIsTablet from "../../hooks/useIsTablet";
import { ViewTablet } from "./ViewTablet";
import { ModalNotification } from "./ModalNotification";

const { Content } = Layout;

interface LayoutProps {
  children: React.ReactNode;
  userDb: getUserByEmail_getUserByEmail | null;
  setUserDb?: (e: getUserByEmail_getUserByEmail | null) => void;
}

const LayoutLuca: React.FC<LayoutProps> = ({ children, userDb, setUserDb }) => {
  const location = useLocation();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const lesson = location.pathname.slice(1, 5);
  const [openReward, setReward] = useState(false);
  const [openNotification, setNotification] = useState(false);

  const onCloseModalReward = () => {
    setReward(false);
  };

  useEffect(() => {
    userDb?.avatar_state?.show_finish_tournament && setReward(true);
  }, [userDb]);
  useEffect(() => {
    userDb?.show_popup_notification && setNotification(true);
  }, [userDb]);

  return (
    <>
      {((userDb?.avatar && userDb?.role === "student") ||
        userDb?.role === "teacher" ||
        userDb?.role === "admin" ||
        userDb?.role === "coordinator") &&
        (lesson !== "task" && lesson !== "noSc" ? (
          <>
            {isMobile ? (
              <div className={styles.container}>
                <div className={styles.contentContainer}>{children}</div>
                <div className={styles.footerMobile}>
                  <SiderLuca userDb={userDb} isMobile={isMobile} />
                </div>
              </div>
            ) : isTablet ? (
              <ViewTablet />
            ) : (
              <Layout style={{ minHeight: "100vh" }}>
                <SiderLuca userDb={userDb} isMobile={isMobile} />
                <Layout style={{ background: "white" }}>
                  <Content style={{ background: "none !important" }}>
                    <div className={styles.siteLayout}>{children}</div>
                  </Content>
                </Layout>
              </Layout>
            )}
          </>
        ) : (
          <div> {children}</div>
        ))}

      {!userDb?.avatar && userDb?.role === "student" && (
        <SelectAvatar userDb={userDb} setUserDb={setUserDb} />
      )}
      <ModalReward
        onCloseModalPosition={onCloseModalReward}
        openModal={openReward}
        userScore={userDb}
      />

      <ModalNotification
        setNotification={setNotification}
        openModal={openNotification}
      />
    </>
  );
};

export default LayoutLuca;
