import programDrawer from "../../../../../assets/icons/sider/programDrawer.png";
import englishDrawer from "../../../../../assets/icons/sider/englishDrawer.png";
import literacyDrawer from "../../../../../assets/icons/sider/literacyDrawer.png";
import literacyDrawerStudent from "../../../../../assets/icons/sider/literacyDrawerStudent.png";
import { getUserByEmail_getUserByEmail } from "../../../../../__generated__/gql-types/getUserByEmail";
interface ButtonConfig {
  visible: boolean;
  label: string;
  img: string;
  route: string;
}

export const getButtonConfig = (
  userDb: getUserByEmail_getUserByEmail
): Record<string, ButtonConfig> => {
  const viewEnglish = userDb?.schools[0]?.view_english;
  const studentViewNem = userDb?.schools[0]?.view_NEM_student;
  const studentView2017 = userDb?.schools[0]?.view_2017_student;

  const studentViewLiteracy =
    userDb?.schools[0]?.view_library_english ||
    userDb?.schools[0]?.view_library_spanish;

  const teacherViewLiteracy =
    userDb?.schools[0]?.view_reading_spanish ||
    userDb?.schools[0]?.view_reading_english;

  const visible_spanish_language = userDb?.visible_spanish_language;
  const visible_english_language = userDb?.visible_english_language;
  const isTeacher = userDb.role === "teacher" || userDb.role === "coordinator";
  return {
    nem: {
      visible:
        (studentViewNem && !isTeacher) ||
        (isTeacher && visible_spanish_language),
      label: isTeacher ? "Programa Luca" : "CURSO LUCA",
      img: programDrawer,
      route: "/nem",
    },
    "2017": {
      visible:
        (studentView2017 && !isTeacher) ||
        (isTeacher && visible_spanish_language),
      label: isTeacher ? "Programa 2017" : "CURSO 2017",
      img: programDrawer,
      route: "/2017",
    },
    literacy: {
      visible:
        (isTeacher && teacherViewLiteracy) ||
        (!isTeacher && studentViewLiteracy),
      label: isTeacher ? "Lectura" : "BIBLIOTECA",
      img: !isTeacher ? literacyDrawerStudent : literacyDrawer,
      route: "/literacy",
    },
    english: {
      visible:
        viewEnglish && isTeacher && visible_english_language ? true : false,
      label: "Programa Inglés",
      img: englishDrawer,
      route: "/english",
    },
  };
};
