import React, { FC, useState } from "react";
import styles from "./headerPoints.module.scss";
import { getClassroomById_getClassroomById } from "../../../__generated__/gql-types/getClassroomById";
import { ListTournament } from "./ListTournament";
import { LeaderShareProps } from "../utils";

interface HeaderPointsProps {
  setTabState: (e: string) => void;
  tabState: string;
  classRomm: getClassroomById_getClassroomById | null;
  setTablaScore: (e: LeaderShareProps) => void;
}

const HeaderPoints: FC<HeaderPointsProps> = ({
  setTabState,
  tabState,
  classRomm,
  setTablaScore,
}) => {
  const [openList, setOpenList] = useState(false);

  return (
    <div className={styles.botonContainer}>
      <div className={styles.leftBotons}>
        <button
          className={
            tabState === "pointer"
              ? styles.botonSelected
              : styles.botonDeselected
          }
          onClick={() => {
            setTabState("pointer");
          }}
        >
          Puntos
        </button>
        <button
          className={
            tabState === "clasification"
              ? styles.botonSelected
              : styles.botonDeselected
          }
          onClick={() => {
            setTabState("clasification");
          }}
        >
          Torneo
        </button>
      </div>
      {/* {tabState === "clasification" && (
        <button
          className={styles.botonTournement}
          onClick={() => {
            setOpenList(!openList);
          }}
          style={openList ? { borderRadius: "10px 10px 0px 0px" } : {}}
        >
          Seleccionar Torneo
          {openList && (
            <ListTournament
              classRomm={classRomm}
              setTablaScore={setTablaScore}
            />
          )}
        </button>
      )} */}
    </div>
  );
};

export default HeaderPoints;
