import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";

import {
  getTeacherProjectsByTeacherId,
  getTeacherProjectsByTeacherIdVariables,
} from "../__generated__/gql-types/getTeacherProjectsByTeacherId";

const useGetTeacherProjectByTeacherId = () => {
  const client = useApolloClient();

  return useCallback(
    async (input: getTeacherProjectsByTeacherIdVariables) => {
      const result: ApolloQueryResult<getTeacherProjectsByTeacherId> =
        await client.query({
          query: GET_TEACHER_PROJECTS_BY_TEACHER_ID,
          fetchPolicy: "no-cache",
          variables: input,
        });

      if (result.error) {
        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return result.data.getTeacherProjectsByTeacherId;
    },
    [client]
  );
};

export default useGetTeacherProjectByTeacherId;

const GET_TEACHER_PROJECTS_BY_TEACHER_ID = gql`
  query getTeacherProjectsByTeacherId(
    $getProjectsSearchArgs: GetProjectsSearchArgs
  ) {
    getTeacherProjectsByTeacherId(
      getProjectsSearchArgs: $getProjectsSearchArgs
    ) {
      title
      grade
      activity_type
      duration
      week_hours
      user_id
      url
      is_corrupted
      percent
      students
      themes_of_interest
      id
      created_at
      updated_at
      deleted_at
    }
  }
`;
