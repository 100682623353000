import { useCallback } from "react";
import {
  ApolloError,
  ApolloQueryResult,
  useApolloClient,
  gql,
} from "@apollo/client";
import {
  getUserByEmailVariables,
  getUserByEmail,
  getUserByEmail_getUserByEmail,
} from "../__generated__/gql-types/getUserByEmail";
import { Roles } from "../__generated__/gql-types/globalTypes";

const UseGetUserByEmail = () => {
  const client = useApolloClient();
  return useCallback(
    async (input: getUserByEmailVariables) => {
      const result: ApolloQueryResult<getUserByEmail> = await client.query({
        query: GET_USER_BY_EMAIL,
        fetchPolicy: "no-cache",
        variables: input,
      });

      if (result.error) {
        localStorage.setItem("token", "");

        throw new ApolloError({
          graphQLErrors: result.errors,
        });
      }

      return {
        ...result.data.getUserByEmail,
        role:
          result?.data?.getUserByEmail?.role === "coordinator"
            ? Roles.teacher
            : result.data.getUserByEmail?.role,
      } as getUserByEmail_getUserByEmail;
    },
    [client]
  );
};

export default UseGetUserByEmail;

const GET_USER_BY_EMAIL = gql`
  query getUserByEmail($userEmail: String) {
    getUserByEmail(userEmail: $userEmail) {
      id
      first_name
      last_name
      email
      avatar
      show_popup_notification
      role
      email_parent
      visible_spanish_language
      visible_english_language
      user_grade

      schools {
        name
        demo
        show_sep_pages
        show_grades_on_base_100
        view_literacy
        view_english
        view_reading_spanish
        view_reading_english
        view_2425
        view_NEM_student
        view_2017_student
        view_2425_student
        view_shop
        view_project_generator
        view_dynamics
        view_library_english
        view_library_spanish
      }
      score {
        id
        math_score
        spanish_score
        science_score
        english_score
        main_score
        user_id
        level
        math_medal
        spanish_medal
        science_medal
        main_medal
        math_average
        science_average
        english_average
        spanish_average
      }
      groups {
        grade {
          id
          grade
        }
        id
        name
        grade_id
      }
      avatar_state {
        trophies {
          position
        }
        show_finish_tournament
      }
      classrooms {
        name
        id
        program {
          subject_id
          subject {
            name
          }
          grade {
            grade
          }
        }
        group {
          name
          users {
            id
          }
        }
      }
    }
  }
`;
